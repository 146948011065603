import axios from "axios";
import { getJwtToken, getRefreshToken, setTokenInHeader } from "./axios_helper";
import { BASEURL } from "../../utils/config"


const _axiosNoInterceptor = axios.create();

const _axios = axios.create({
	baseURL: BASEURL,
	// withCredentials: true,
});

_axios.interceptors.request.use(
	async (config) => {
		config = setTokenInHeader(config);
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);


_axios.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;



		if (error.response.status === 401 && originalRequest.url !== '/login/') {
			localStorage.removeItem('userInfo');
			localStorage.removeItem('userCourses');
			localStorage.removeItem('jwtToken');
			localStorage.removeItem('refreshToken');
			window.location.href = "/login"

			// originalRequest._retry!
			// originalRequest._retry = true;

			// 
			// const refreshToken = getRefreshToken();
			// 
			// if (refreshToken) {
			// 	
			//   _axiosNoInterceptor
			// 	.post(`${BASEURL}/login/refresh/`, {
			// 	  refresh: localStorage.refreshToken,
			// 	})
			// 	.then((res) => {
			// 	  if (res?.data?.access) {
			// 		window.localStorage.setItem("jwtToken", res.data.access);
			// 		axios.defaults.headers.common["Authorization"] =
			// 		  "Bearer " + res.data.access;
			// 		originalRequest.headers["Authorization"] =
			// 		  "Bearer " + res.data.access;
			// 		return axios(originalRequest);
			// 	  }
			// 	}).catch(err => {
			// 		
			// 	});
			// }
		}
		if (error.response.status === 403 && originalRequest.url !== '/login/') {
			localStorage.removeItem('userInfo');
			localStorage.removeItem('userCourses');
			localStorage.removeItem('jwtToken');
			localStorage.removeItem('refreshToken');
			window.location.href = "/login"
		}
		return Promise.reject(error);
	}
);

export { _axios, _axiosNoInterceptor };
