const getJwtToken = () => {
    const token = localStorage.getItem("jwtToken");
    return token;
};

const getRefreshToken = () => {
    const token = localStorage.getItem("refreshToken");
    return token;
};
  
const setTokenInHeader = (config) => {
    config.headers.common['Content-Type'] = 'application/json';
    config.headers.common['Accept'] = 'application/json';
    
    if (localStorage.getItem("jwtToken")) {
        const token = getJwtToken();
        if (token) {
            config.headers.common["Authorization"] = `Bearer ${token}`;
        }
    }else{
        config.headers.common['Authorization'] = null;
    }
  
    return config;
};





  
export {
    getJwtToken,
    getRefreshToken,
    setTokenInHeader
};
  