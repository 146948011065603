import React from 'react';
import OptionChoice from '../option_choice/OptionChoice';
import DragDrop from '../drag_drop/DragDrop';
import ReactMarkdown from 'react-markdown'

const Question = ({ item, handleChoice, value, disabled, onDragEnd }) => {
    return (
        <React.Fragment>

            <div className="content-wrap-pad">
                {console.log(item)}
            {item.title && (<ReactMarkdown  children={item.title} allowDangerousHtml />)}
                    
                    {item.image && (
                        <div className="question_img_area">
                            <img src={item.image} alt=""/>
                        </div>
                    )}
                    
                

                
                {item.type.value === 'SINGLE_CHOICE' && (
                    <div className="mt-5 mb-5 ml-2">
                        <OptionChoice options={item.options.choices} handleChoice={handleChoice} type="radio" choice={value} disabled={disabled}/>
                    </div>
                )}
            
            
                {item.type.value === 'MULTIPLE_CHOICE' && (
                    <div className="mt-5 mb-5 ml-2">
                        <OptionChoice options={item.options.choices} handleChoice={handleChoice} type="checkbox" choice={value} disabled={disabled}/>
                    </div>
                )}
            
            
                {item.type.value === 'DRAG_DROP' && (
                    <div className="mt-5 mb-5 ml-2">
                        <DragDrop items={item.options} droppable={item.options.direction} answer={value} onDragEnd={onDragEnd} disabled={disabled}/>
                    </div>
                )}
                

            </div>

        </React.Fragment>
    )
}

export default Question
