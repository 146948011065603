import React, { useState, useEffect, Fragment } from 'react';
import { Form, Button} from 'react-bootstrap';
import "../login/Login.css";
import loginBg from "../../../assets/images/bg-3.jpg";

import Logo from "../../../assets/images/logo.png";
import { Link } from 'react-router-dom';

const Forgot = () => {
    const [email, setEmail] = useState('')
    
    return (
        <Fragment>
            <div className="d-flex justify-content-center align-items-center  flex-row-fluid  login-content">
                <div className="login-area login-form text-center p-7 position-relative overflow-hidden bgi-size-cover bgi-position-top bgi-no-repeat" style={{backgroundImage: `url(${loginBg})`}}>
                    <div className="login-area-logo mb-15">
                        <img src={Logo} alt="logo.png"/>
                    </div>
                    <div className="mb-20">
                        <h3 className="fs-7">Forgotten Password?</h3>
                        <p className="text-muted fs-6">Enter your email to reset your password.</p>
                    </div>
                   
                    <Form>
                        <Form.Group controlId='username'>
                            <Form.Control className="login-input-form px-8 py-3 fs-6" type='text' value={email} placeholder='Email' onChange={(e) => setEmail(e.target.value)}></Form.Control>
                        </Form.Group>

                        <div className="d-flex justify-content-start align-items-center">
                            <Button type='submit' className="c-btn-primary px-9 py-2 my-2 mx-2 rounded-0">Submit</Button>
                            <Link to="/login" className="c-btn-secondary px-9 py-2 my-2 mx-2 rounded-0">Cancel</Link>
                        </div>
                    </Form>
                </div>
            </div>
        </Fragment>
    )
}

export default Forgot
