import {
    CREATE_MESSAGE,
    CLEAR_MESSAGE,
} from '../constants/messageConstants';

const initialState = {}

export const messageReducer = (state = initialState,action) => {
    switch (action.type) {
        case CREATE_MESSAGE:
            return (state = action.payload)
        case CLEAR_MESSAGE:
            return {}
        default:
            return state
    }
}