import { combineReducers } from 'redux';

import  {DESTROY_SESSION} from '../constants/userConstants'

import { messageReducer } from './messageReducer';
import {errorReducer} from './errorReducer';
import { userReducer } from './userReducer';
import { courseReducer } from './courseReducer';
import { examReducer } from './examReducer';
import { examReviewReducer } from './examReviewReducer'
import { settingsReducer } from './settingsReducer'




// export default combineReducers({
//     messages: messageReducer,
//     errors: errorReducer,
//     user: userReducer,
//     course: courseReducer,
//     exam: examReducer,
//     examReview: examReviewReducer,
//     settings: settingsReducer
// });

const appReducer = combineReducers({
    messages: messageReducer,
    errors: errorReducer,
    user: userReducer,
    course: courseReducer,
    exam: examReducer,
    examReview: examReviewReducer,
    settings: settingsReducer
});


const rootReducer = (state, action) => {   
    // Clear all data in redux store to initial.
    if(action.type === DESTROY_SESSION){
        state.course = undefined;
        state.exam = undefined;
        state.examReview = undefined;
        state.settings = undefined;
    }
    

    return appReducer(state, action);
};

export default rootReducer;