import React, {useState, useEffect} from 'react';
import {DndContext} from '@dnd-kit/core';

import {Droppable} from './Droppable';
import {Draggable} from './Draggable';
import _, { isArray } from 'lodash';
/* 
import {
  arrayMove,
} from '@dnd-kit/sortable';
 */
export default function DragDrop(props) {
  console.log(props);
  
  const [parent, setParent] = useState([]);
  const[draggables, setDraggables] = useState([]);
  const[droppables, setDroppables] = useState([]);


  const swapArrayElements = (arr, x, y) => {
    if (arr[x] === undefined || arr[y] === undefined || arr[x]===arr[y]) {
      return arr
    }
    const a = x > y ? y : x
    const b = x > y ? x : y
    return [
      ...arr.slice(0, a),
      arr[b],
      ...arr.slice(a+1, b),
      arr[a],
      ...arr.slice(b+1)
    ]
  }

  const isArrayEqual = (x, y) => {
    return _(x).xorWith(y, _.isEqual).isEmpty();
  };

  const checkValuePresenceInArray = (array, value) => array.some(obj => Object.values(obj).includes(value));
  const arrayHasObject = array => array.some( value => { return typeof value == "object" } );
  
  useEffect(() => {
    const droppableItems = props.items[`${props.droppable}`];
    const draggableItems = props.droppable==="left" ? props.items.right : props.items.left;
    setDraggables(draggableItems);
    setDroppables(droppableItems);
  }, [props.droppable, props.items])
  
  useEffect(() => {
    if(Array.isArray(props.answer) && arrayHasObject(props.answer)){
      setParent(props.answer);
    }else{
      setParent([]);
    }
  }, [props.answer])
  
  useEffect(() => {

    if(Array.isArray(parent)){
      let parentClone = parent;
      setDraggables((prevState) => {
        let newDraggables = prevState;
        Array.isArray(parentClone) && arrayHasObject(parentClone) && parentClone.forEach(obj=>{
          const overItem = (element) => element.k === Object.keys(obj)[0];
          const activeItem = (element) => element.k === Object.values(obj)[0];
          const oldIndex = newDraggables.findIndex(activeItem);
          const newIndex = droppables.findIndex(overItem);
          newDraggables = swapArrayElements(newDraggables, newIndex, oldIndex);
        })
        return (newDraggables);
      })
    }
  }, [parent])

  let draggableMarkup = draggables.map((item)=>(
    <Draggable id={item.k} key={item.k} className="draggable-item" disabled={props.disabled}>{`${item.k}. ${item.t}`}</Draggable>
  ))

  let droppableMarkup = droppables.map((itm) => (
    <Droppable key={itm.k} id={itm.k}>
        {`${itm.k}. ${itm.t}`}
    </Droppable>
  ))




  const draggableList = draggableMarkup.map((item)=>(
    checkValuePresenceInArray(parent, item.key) ?
    React.cloneElement(item,{className: item.props.className + ` dragged-${props.droppable}`})
    :
    item
  ))
 
  //  useEffect(() => {


  //  },[droppables]) 




  return (
    
      <div className="dragdropContainer" style={
        {
          display:'grid', 
          gridTemplateRows: `repeat(${droppables.length}, 1fr)`,
          gridTemplateColumns: 'repeat(2, 1fr)', 
          gridAutoFlow: 'column',
          gridGap:'1rem'
        }
      }>
        <DndContext onDragEnd={props.onDragEnd}>
          {
            props.droppable==='left' && droppableMarkup
          }
          {
            draggableList
          }

          {
            props.droppable==='right' && droppableMarkup
          }
        </DndContext>
      </div>
  );
};