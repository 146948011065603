export const checkExtension = (str) => {
    if(str === 'mp4' || str === 'mpg' ||  str === 'avi' || str === 'wmv'){
        return 'VIDEO'
    }
    else if(str === 'pdf' || str === 'doc' || str === 'docx'){
        return 'DOCUMENT'
    }
    else if(str === 'jpg'){
        return 'IMAGE'
    }
}
