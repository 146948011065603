import React,{ Fragment,useEffect } from 'react'
import { useSelector } from 'react-redux'
import { withAlert,useAlert } from 'react-alert';


const Message = () => {

    const errors = useSelector(state => state.errors)
    const messages = useSelector(state => state.messages)


    const alert = useAlert()

    

    useEffect(() => {
        if(errors && errors.msg && errors.msg.length > 0){
            errors.msg.map((item,idx) => {
                if(item.field_errors){
                    {Object.keys(item.field_errors).map((key)=>(
                        alert.error(`${key} : ${item.field_errors[key]}`)
                    ))}
                }
                if(item.non_field_errors){
                    {Object.keys(item.non_field_errors).map((key)=>(
                        alert.error(`${key} : ${item.non_field_errors[key]}`)
                    ))}
                }
            })
        }

        if(messages.login){
            alert.success(messages.login);
        }
        if(messages.logout){
            alert.success(messages.logout);
        }
        if(messages.permission){
            alert.success(messages.permission);
        }
        if(messages.markForReview){
            alert.success(messages.markForReview);
        }
        if(messages.answerSubmitted){
            alert.success(messages.answerSubmitted);
        }

        if(messages.examEnd){
            alert.success(messages.examEnd);
        }
        if(messages.password_change){
            alert.success(messages.password_change);
        }
        if(messages.password_not_match){
            alert.error(messages.password_not_match);
        }
        

        
    },[errors,messages,alert])

    

    return (
        <Fragment />
    )
}

export default withAlert()(Message)