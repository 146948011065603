import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import {
    ProSidebar,
    Menu,
    MenuItem,
    SubMenu,
    SidebarHeader,
    SidebarContent,
    SidebarFooter,
} from 'react-pro-sidebar'
import 'react-pro-sidebar/dist/css/styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faBook, faUserEdit, faInfoCircle, faQuestionCircle, faVideo, faUserCog } from "@fortawesome/free-solid-svg-icons";
import "./SideBar.css";

import logoImg from "../../../assets/images/logo.png";

import { APP_VERSION } from '../../../utils/config'

import { checkSubscription } from '../../../config/subscriptionCheck'
import { useState } from 'react';

const SideBar = ({ collapsed, toggled, handleToggleSidebar }) => {

    const user = useSelector(state => state.user)
    const { userCourses } = user
    const [lessons, setLessons] = useState([])
    const [check, setCheck] = useState(false);

    function handleActive(event) {
        setCheck(prevCheck => !prevCheck);
    }


    return (
        <div className="sidebar custom-sidebar">
            <ProSidebar
                image={false}
                collapsed={collapsed}
                toggled={toggled}
                breakPoint="md"
                onToggle={handleToggleSidebar}
            >
                <SidebarHeader>
                    <div className={collapsed ? "logo_collapsed" : "logo_with_out_collapsed"}>
                        <img src={logoImg} alt="" />
                    </div>
                </SidebarHeader>

                <SidebarContent>
                    <Menu iconShape="circle">
                        <SubMenu
                            title="Classroom Materials"
                            icon={<FontAwesomeIcon icon={faBook} className="menu_item_icon_color" />}
                        >
                            {
                                userCourses && userCourses.length !== 0 && userCourses.map((item, idx) => (
                                    item.subscriptions && item.subscriptions.length && checkSubscription(item.subscriptions, 'document') && (
                                        <MenuItem key={idx}>
                                            {item.title}
                                            <Link to={`/course/topic/${item.id}/document`} onClick={() => handleToggleSidebar(false)} />
                                        </MenuItem>
                                    )
                                ))
                            }
                            {/* {
                                userCourses && userCourses.length !== 0 && userCourses.map((item, idx) => (
                                    item.subscriptions && item.subscriptions.length && checkSubscription(item.subscriptions, 'document') && (
                                        <SubMenu title={item.title}>
                                           {
                                                item.lessons.length ? (item.lessons.map((lesson, indx) =>(
                                                    <MenuItem key={indx} active={window.location.pathname === `/course/topic/${lesson.id}/document` ? false : true}>
                                                            {lesson.name}
                                                            <Link to={`/course/topic/${lesson.id}/document`} onClick={handleActive} />
                                                    </MenuItem>
                                                )))
                                                :null
                                            }
                                        </SubMenu>
                                    )


                                ))
                            } */}
                        </SubMenu>

                    </Menu>
                    <Menu iconShape="circle">
                        <SubMenu
                            title="Video Materials"
                            icon={<FontAwesomeIcon icon={faVideo} className="menu_item_icon_color" />}
                        >
                            {
                                userCourses && userCourses.length !== 0 && userCourses.map((item, idx) => (
                                    item.subscriptions && item.subscriptions.length && checkSubscription(item.subscriptions, 'video') && (
                                        <SubMenu title={item.title}>
                                            {
                                                item.lessons.length ? (item.lessons.map((lesson, indx) => (
                                                    <MenuItem key={indx} active={window.location.pathname === `/course/topic/${lesson.id}/video` ? true : false}>
                                                        {lesson.name}
                                                        <Link to={`/course/topic/${lesson.id}/video`} onClick={handleActive} />
                                                    </MenuItem>
                                                )))
                                                    :
                                                    null
                                            }
                                        </SubMenu>
                                    )


                                ))
                            }
                        </SubMenu>

                    </Menu>

                    <Menu iconShape="circle">
                        <SubMenu
                            title="Exam Simulator"
                            icon={<FontAwesomeIcon icon={faUserEdit} className="menu_item_icon_color" />}
                        >
                            {
                                userCourses && userCourses.length !== 0 && userCourses.map((item, idx) => (
                                    item.subscriptions && item.subscriptions.length && checkSubscription(item.subscriptions, 'exam') && (
                                        <MenuItem key={idx}>
                                            {item.title}
                                            <Link to={`/exam/select/${item.id}`} onClick={() => handleToggleSidebar(false)} />
                                        </MenuItem>
                                    )


                                ))
                            }
                            <MenuItem>
                                Exam Dashboard
                                <Link to="/exam-list" onClick={() => handleToggleSidebar(false)} />
                            </MenuItem>
                        </SubMenu>
                    </Menu>

                    <Menu iconShape="circle">

                        <SubMenu
                            title="Profile/ Settings"
                            icon={<FontAwesomeIcon icon={faUserCog} className="menu_item_icon_color" />}
                        >
                            <MenuItem>
                                Personal Info
                                <Link to="/personal-info" onClick={() => handleToggleSidebar(false)} />
                            </MenuItem>
                            <MenuItem>
                                Account Info
                                <Link to="/account-info" onClick={() => handleToggleSidebar(false)} />
                            </MenuItem>
                            <MenuItem>
                                Change Password
                                <Link to="/change-password" onClick={() => handleToggleSidebar(false)} />
                            </MenuItem>
                        </SubMenu>
                    </Menu>




                    <Menu iconShape="circle"><MenuItem icon={<FontAwesomeIcon icon={faInfoCircle} className="menu_item_icon_color" />}>Contact Us</MenuItem></Menu>

                    <Menu iconShape="circle">
                        <MenuItem icon={<FontAwesomeIcon icon={faQuestionCircle} className="menu_item_icon_color" />}>
                            FAQ
                            <Link to="/faq" onClick={() => handleToggleSidebar(false)} />
                        </MenuItem>

                    </Menu>




                </SidebarContent>
                {/* <SidebarFooter>
                    <div
                        className="sidebar-btn-wrapper bg-dark text-white m-0 pl-2 text-center"
                        style={{
                            padding: '20px 24px',
                        }}
                    >
                        <span>Version {APP_VERSION}</span>
                    </div>
                </SidebarFooter> */}
            </ProSidebar>
        </div>
    )
}

export default SideBar
