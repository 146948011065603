import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'react-bootstrap/Pagination';

import QuestionReviewModal from '../../modal/question_review/QuestionReviewModal';
import CountDownTimer from '../../count_down/CountDownTimer';
import { Button, Form, Modal } from 'react-bootstrap';

import { examReviewList, endExam } from '../../../../actions/examReviewActions';
import { useHistory } from 'react-router-dom';
import "./QuestionFooter.css";
import { DragDrop } from '../drag_drop/DragDrop'

const ExerciseFooter = ({ disabled, paginate, nextPrevPage, forSubmit, onDragEnd, totalItems, itemPerPage, currentPage, examtype, markForReview, answer, showAnswer, handleShowAnswer, userExamId, marked, id, handleReset, isMarkForReviewBreak, backFormreview, initiateBreak, break_time, cancelBreak, showSpecialModal }) => {
    const [modalShow, setModalShow] = useState(false);
    const [isMarked, setIsMarked] = useState(marked);

    const dispatch = useDispatch();

    useEffect(() => {
        setIsMarked(marked);
    }, [marked])

    let history = useHistory();
    let pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems.length / itemPerPage); i++) {
        pageNumbers.push(i);
    }

    const getReviewList = () => {
        setModalShow(true);
        if (userExamId) {
            dispatch(examReviewList(userExamId, examtype));
        }
    }

    // const endMainExam = () => {
    //     dispatch(endExam(userExamId, history));
    // }

    const handleClick = () => {
        setIsMarked(!isMarked)
        markForReview(!isMarked)
    }


    const stopBreak = () => {
        cancelBreak();
    }





    const examReview = useSelector(state => state.examReview)
    const { reviewList, loading } = examReview



    return (
        <Fragment>


            <div className="content-wrap-pad d-flex justify-content-between align-items-center flex-wrap">
                <div className="question_footer_item d-flex mb-2 align-items-center">
                    {examtype === 'PRACTICE' ? (
                        // <Button className="btn-warning rounded-0 mr-2" onClick={handleReset}>Reset</Button>
                        ''
                    ) :
                        !isMarkForReviewBreak && (
                            <Link to="/exam-list" className="btn btn-danger rounded-0 mr-2">End Exam</Link>
                        )

                    }


                </div>


                {!isMarkForReviewBreak ? (
                    <div className="content-wrap-pad d-flex align-items-center flex-wrap">
                        {/* <div className="question_heading_item_one mb-2">
                            <Form.Check
                                type="checkbox"
                                label="Mark For Review"
                                name="mark_for_review"
                                onChange={handleClick}
                                checked={isMarked}
                                className="ml-1 mr-1"
                                disabled={disabled}
                            />
                        </div> */}
                        <div className="question_heading_item_two d-flex align-items-center mb-2">
                            <div className="mb-2">
                                {/* <Button className=" ml-1 mr-1 rounded-0 c-btn-info" onClick={getReviewList}>
                                    Review
                                </Button> */}
                                <QuestionReviewModal
                                    examtype={examtype}
                                    userExamId={examtype == 'PRACTICE' ? id : userExamId}
                                    reviewList={reviewList}
                                    loading={loading}
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                />
                            </div>


                            <div className="mb-2">
                                {examtype === 'PRACTICE' && (
                                    <Button className=" ml-1 mr-1 c-btn-primary rounded-0" onClick={(e) => handleShowAnswer(!showAnswer)}>
                                        Pick Answer
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="content-wrap-pad d-flex align-items-center flex-wrap">
                            <div className="question_heading_item_two d-flex align-items-center mb-2">
                                <div className="mb-2">
                                    <Button className=" ml-1 mr-1 rounded-0 c-btn-info" onClick={backFormreview}>
                                        Continue
                                    </Button>
                                    <Button className=" ml-1 mr-1 rounded-0 c-btn-info" onClick={initiateBreak}>
                                        Break
                                    </Button>

                                </div>
                            </div>
                        </div>

                        <Modal show={showSpecialModal} backdrop={'static'}>
                            <Modal.Header>
                                <Modal.Title>Exam Break</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <div className="question_break_footer">
                                    <CountDownTimer
                                        duration={break_time}
                                        text={'Exam resumes in'}
                                        onComplete={backFormreview}
                                    />
                                    <Button className="btn btn-success rounded-0 question_break_footer_btn" onClick={stopBreak}>Continue</Button>
                                </div>
                            </Modal.Body>

                        </Modal>

                    </>

                )}



                <div className="question_footer_item mb-2">
                    <Pagination>
                        {
                            pageNumbers.includes(currentPage - 1) && (
                                <Button className="mr-1 rounded-0 c-btn-info" onClick={() => nextPrevPage(currentPage - 1, false)}>Prev</Button>
                            )
                        }

                        {/* {pageNumbers.map(number => (
                            <Pagination.Item key={number} onClick={() => paginate(number)}>
                                {number}
                            </Pagination.Item>
                        ))} */}


                        {
                            totalItems.length !== currentPage ? (
                                <Button className="ml-1 rounded-0 c-btn-info" onClick={() => nextPrevPage(currentPage + 1, true)}>Next</Button>

                            )
                                :
                                ''
                        }


                    </Pagination>
                </div>



            </div>
        </Fragment>
    )
}

export default ExerciseFooter
