import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReactMarkdown from 'react-markdown'

import Loader from '../../../components/helpers/Loader';
import ContentHeader from '../../../components/layout/content_header/ContentHeader';
import NoData from "../../../components/layout/card/blank_page/BlankPage";

import { getFaqList } from '../../../actions/settingsActions';

import Accordion from '../../../components/layout/accordian/Accordian';
import './Faq.css'

const Faq = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getFaqList())
    }, [])

    const { faq, loading } = useSelector(state => state.settings)

    return (
        <Fragment>
            <div className="content_bg pb-3">
                {loading && <Loader />}

                {!loading && faq && (<ContentHeader title="Frequently Asked Question" sub_title="FAQ" />)}


                <div className="wrapper">
                    {!loading && faq && faq.length ? faq.map((item, idx) => (
                        <Accordion title={item.question} key={idx}>
                            <ReactMarkdown children={item.answer} allowDangerousHtml />
                        </Accordion>

                        // <div key={idx} className="content-wrap-pad mb-3 w-100">
                        //     {item.question} -- {item.answer}
                        // </div>

                    )) : !loading && !faq && <NoData />}
                </div>


            </div>
        </Fragment>
    )
}

export default Faq
