import React, {useState, useEffect} from 'react';
import { Modal, Button, Row, Col, Container } from 'react-bootstrap';
import CountDownTimer from '../../count_down/CountDownTimer';
import "./ExamBreakModal.css";

const ExamBreakModal = (props) => {
    const[examBreak, setExamBreak] = useState(false);

    const stopBreak = () => {
        setExamBreak(false);
        props.cancelBreak();
    }
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop={props.backdrop}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Exam Break
                </Modal.Title>
            </Modal.Header>
            {examBreak && 
                <Modal.Body>
                    <div className="exam_break">
                        <CountDownTimer 
                            duration={props.break_time} 
                            text={'Exam resumes in'} 
                            onComplete={()=>{
                                setExamBreak(false);
                                props.cancelBreak();
                            }}
                        />
                        <Button className="btn btn-success rounded-0 exam_break_btn" onClick={stopBreak}>Continue</Button>
                    </div>
                </Modal.Body> ||
                <Modal.Body className="min-height-150">
                    <p>
                        You Can Take Break Or Continue Your Exam.
                    </p>
                </Modal.Body>
            }

            {!examBreak && <Modal.Footer className="d-flex justify-content-start">
                <Button className="btn btn-warning rounded-0" onClick={props.reviewBreak}>Review Exam</Button>
                
                <Button className="btn btn-success rounded-0" onClick={props.cancelBreak}>Continue Exam</Button>
                
                <Button 
                    className="btn btn-danger rounded-0 float-right" 
                    onClick={()=>{
                        props.initiateBreak();
                        setExamBreak(true);
                    }}
                >Take Break
                </Button>
                
            </Modal.Footer>}
        </Modal>
    )
}

export default ExamBreakModal
