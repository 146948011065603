import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ExamCard from '../../../components/layout/card/exam/ExamCard';
import ReactBootstrapTable from '../../../components/layout/table/ReactBootstrapTable';

import DateFilter from '../../../components/layout/filter/date_filter/DateFilter';
import ContentHeader from "../../../components/layout/content_header/ContentHeader";
import MyPagination from '../../../components/layout/pagination/MyPagination';

import BlankPage from '../../../components/layout/card/blank_page/BlankPage'
import { dashboardExamList } from './mockData';
import { tableColumns } from './TableColumn';
import Loader from '../../../components/helpers/Loader';

import { getUserExams } from '../../../actions/examActions';

const ExamList = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);


  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;

  const dispatch = useDispatch();

  const handleEdit = (item) => () => {
    // write your logic
    history.push(`/exam/details/${item.id}`)
  }

  const handleDateFilter = (value) => {
    alert(value)
  }



  useEffect(() => {
    dispatch(getUserExams())
  }, [])

  const userExamList = useSelector(state => state.exam.userExamList);
  const loading = useSelector(state => state.exam.loading);


  const currentExamList = userExamList && userExamList.length ? userExamList.slice(indexOfFirstItem, indexOfLastItem) : [];

  const paginate = (number) => {
    setCurrentPage(number);
  }

  const nextPrevPage = (number) => {
    if (number) {
      paginate(number);
    }
  }

  return (

    <Fragment>
      {loading && <Loader />}
      {!loading && userExamList && userExamList.length && (
        <div className="">
          {/* <ExamCard inProgressExam={userExamList ? userExamList.in_progress : []}/> */}

          <div className="mt-3 content_bg">
            <div className="my_table_with_action">
              <ContentHeader title="My Recent Results" sub_title="Showing here your recent participant exam results" />
              {/* <DateFilter handleDateFilter={handleDateFilter} /> */}
            </div>

            <ReactBootstrapTable data={currentExamList ? currentExamList : []} cols={tableColumns(handleEdit)} />
            <MyPagination nextPrevPage={nextPrevPage} paginate={paginate} totalItems={userExamList ? userExamList : []} itemPerPage={itemPerPage} currentPage={currentPage} />
          </div>
        </div>
      )|| 
      <div className="">
      {/* <ExamCard inProgressExam={userExamList ? userExamList.in_progress : []}/> */}

      <div className="mt-3 content_bg">
        <div className="my_table_with_action">
          <ContentHeader title="My Recent Results" sub_title="Showing here your recent participant exam results" />
          {/* <DateFilter handleDateFilter={handleDateFilter} /> */}
        </div>
      </div>
      <BlankPage />
      {/* <div className="mt-3 content_bg">
                <h1 className="new-style text-dark">
                    No Data Available!
                </h1>
            </div> */}
    </div>
      }

    </Fragment>




  )
}

export default ExamList
