import React,{ Fragment,useState } from 'react'
import { Pagination } from 'react-bootstrap';

import { Button } from 'react-bootstrap';

const MyPagination = ({ nextPrevPage, totalItems, itemPerPage, currentPage,paginate }) => {

    let pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems.length / itemPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <React.Fragment>
            <div className="content-wrap-pad pagination-overfolow">
                <Pagination>
                    {
                        pageNumbers.includes(currentPage - 1) && (
                            <Button className="mr-1 rounded-0 c-btn-info" onClick={() => nextPrevPage(currentPage - 1)}>Prev</Button>
                        )
                    }

                    {pageNumbers.map(number => (
                        <Button key={number} className="ml-1 rounded-0 c-btn-info" onClick={() => paginate(number)}>{number}</Button>
                        ))}


                    {
                        totalItems.length !== currentPage && (
                            <Button className="ml-1 rounded-0 c-btn-info" onClick={() => nextPrevPage(currentPage + 1)}>Next</Button>

                        )
                    }


                </Pagination>
            </div>
        </React.Fragment>
    )
}

export default MyPagination
