import axios  from 'axios';

const setAuthToken = (token) => {
	axios.defaults.headers.common['Content-Type'] = 'application/json';

	const tokenFromLocalStorage = localStorage.getItem('jwtToken')
    
    if(token){
		//Apply to every to Req
		axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	}else {
		//Delete auth header
		delete axios.defaults.headers.common['Authorization'];
	}
}


export default setAuthToken;