import React, { useState } from 'react';

import ReactMarkdown from 'react-markdown'

import './Accordian.css';

const Accordian = ({title,children}) => {
    const [isOpen, setOpen] = useState(false);
    return (
        <div className="accordion-wrapper">

            <div
                className={`accordion-title ${isOpen ? "open" : ""}`}
                onClick={() => setOpen(!isOpen)}
            >
                <ReactMarkdown children={title} allowDangerousHtml />
            </div>
            <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
                <div className="accordion-content">{children}</div>
            </div>
        </div>
    )
}

export default Accordian
