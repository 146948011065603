import React,{Fragment} from 'react'
import { useDispatch } from 'react-redux'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

import { createMessage } from '../../../../actions/messageActions'


import "./CourseMetarial.css";


const CourseMetarial = ({icon,title,link,permission,value}) => {
    const  dispatch = useDispatch();

    let history = useHistory();
    const sendValue = () => {
        if(permission === false){
            dispatch(createMessage({permission: `You dont have permission for ${value}`}));
        }else{
            history.push(link)
        }
    }
    
    return (
        <Fragment>
            <div className="course_name mbl-mb-3">
                <div className="course_icon"> 
                    <FontAwesomeIcon icon={icon} size="xs" />
                </div>
                <a onClick={sendValue} href="#">
                    <h3>{title}</h3>
                </a>
            </div>
        </Fragment>
    )
}

export default CourseMetarial
