import React from 'react';
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton
} from 'video-react';
import 'video-react/dist/video-react.css';

const VideoPlayer = ({ url }) => {

    const play = () => {

    }

    return (
        <Player preload="none">
            <source src={url} />
            <ControlBar autoHide={true}>
                {/* <ReplayControl seconds={10} order={1.1} /> */}
                {/* <ForwardControl seconds={30} order={1.2} /> */}
                <CurrentTimeDisplay order={4.1} />
                <TimeDivider order={4.2} />
                <PlaybackRateMenuButton rates={[1.75, 1.5, 1.25, 1, 0.75, 0.50, 0.25]} order={7.1} />
                <VolumeMenuButton />
            </ControlBar>
        </Player>
    )
}

export default VideoPlayer
