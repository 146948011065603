import {
    EXAM_REVIEW_LIST_REQUEST,
    EXAM_REVIEW_LIST_SUCCESS,
    EXAM_REVIEW_LIST_FAIL,

    
} from '../constants/examReviewConstants'

import { returnErrors, createMessage } from './messageActions';
import { BASEURL } from '../utils/config';


import { _axios } from './axios/axios_conf'





export const examReviewList = (userExamId, type) => (dispatch) => {

    // const examId = `c43081e3-dd96-468d-bb43-1b95354d752a`
    
    dispatch({ type: EXAM_REVIEW_LIST_REQUEST })
    if(type == 'PRACTICE'){
        _axios.get(`/practice-exam-review/${userExamId}`)
        .then(res => {
            dispatch({
                type: EXAM_REVIEW_LIST_SUCCESS,
                payload: res.data.data
            })
        })
        .catch(err => {
            
            dispatch({ type: EXAM_REVIEW_LIST_FAIL })
            dispatch(returnErrors(err.response.data.errors, err.response.status))
        })
    }else{
        _axios.get(`/user-exam-review/${userExamId}`)
        .then(res => {
            dispatch({
                type: EXAM_REVIEW_LIST_SUCCESS,
                payload: res.data.data
            })
        })
        .catch(err => {
            
            dispatch({ type: EXAM_REVIEW_LIST_FAIL })
            dispatch(returnErrors(err.response.data.errors, err.response.status))
        })
    }
    
}
