import React, { useState, Fragment, useEffect, useRef } from 'react';
import {
    useLocation,
} from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ContentHeader from "../../../components/layout/content_header/ContentHeader";
import ExerciseFooter from "../../../components/layout/question/question_footer/ExerciseFooter";
import Question from '../../../components/layout/question/question/Question';
import ShowExerciseAnswer from '../../../components/layout/question/show_answer/ShowExerciseAnswer';
import { getExamContent, removeExamContent, inProgressExam, continueExam, breakExam, markForReviewBreak } from '../../../actions/examActions';
import { practiceExamReset } from '../../../actions/examActions';
import { endExam } from '../../../actions/examActions';



import Loader from '../../../components/helpers/Loader';
const ExerciseForm = ({ match, history }) => {
    const [choice, setChoice] = useState([]);
    const [isMarked, setIsMarked] = useState(false);

    const [modalState, setModalState] = useState(false)
    const [isMarkForReviewBreak, setIsMarkForReviewBreak] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [showAnswer, setShowAnswer] = useState(false);
    const [itemPerPage] = useState(1);
    const [isLocked, setIsLocked] = useState(false);

    const location = useLocation();
    const { method } = location.state || {};
    const examtype = 'PRACTICE';


    const examContentRef = useRef();

    // Get Current Posts
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;

    const dispatch = useDispatch();

    const examContent = match;
    const loading = useSelector(state => state.exam.loading);

    const [showSpecialModal, setShowSpecialModal] = useState(false);



    if (location.state && location.state.index && currentPage !== location.state.index) {
        setCurrentPage(location.state.index);
        const state = { ...history.location.state };
        delete state.index;
        history.replace({ ...history.location, state });
    }





    useEffect(() => {

        setChoice([]);
        setIsMarked(false);
        if (match.id && method && method === 'POST' && examtype) {
            dispatch(getExamContent(match.id, examtype));
        }
        else if (match.id && method && method === 'GET') {
            dispatch(inProgressExam(match.id));
        }
        else {
            dispatch(removeExamContent())
        }
    }, [])

    useEffect(() => {
        return () => {
            handleEndExam()
        }
    }, [])

    const handleEndExam = async () => {
        if (!examContentRef.current) return;
        if (examtype !== 'MAIN'){
            dispatch(removeExamContent());
        }
    }

    const question = examContent

    const currentItem = question.slice(indexOfFirstItem, indexOfLastItem)[0];

    useEffect(() => {
        setChoice([])
        setIsLocked(false);
        if (!currentItem) return;
        setIsMarked(currentItem.mark_for_review)
        if (currentItem && currentItem.user_answer && currentItem.user_answer !== '') {
            setChoice(currentItem.type.value === 'DRAG_DROP' && currentItem.user_answer || currentItem.user_answer.split(','));
        }
        if (examContent && examContent.lock_questions && examContent.lock_questions.includes(currentItem.id)) {
            setIsLocked(true);
        }
    }, [currentItem])

    const paginate = (number) => {
        setCurrentPage(number);
    }

    const nextPrevPage = async (number, examPauseModal, forSubmit) => {
        setShowAnswer(false);

        if (examPauseModal === false) {
            setModalState(false);
        }
        setCurrentPage(number);
        if (number) {
            paginate(number);
        }
        if (forSubmit) {
            history.push('/exam-list');
        }
    }

    const handleDragEnd = (event) => {
        const checkKeyPresenceInArray = (array, key) => array.some(obj => Object.keys(obj).includes(key));
        const { over, active } = event;
        let inDropZone = choice.find(obj => Object.values(obj)[0] === active.id)
        if (over && active) {
            if (checkKeyPresenceInArray(choice, over.id)) {
                alert('Please remove current answer to add new one');
            } else {
                if (inDropZone) {
                    console.log(Object.values(inDropZone)[0])
                    let newParent = choice.filter(obj => Object.values(obj)[0] !== Object.values(inDropZone)[0]);
                    newParent.push({ [over.id]: active.id });
                    console.log(choice, newParent)
                    setChoice(newParent);
                } else {
                    setChoice(prev => [...prev, { [over.id]: active.id }]);
                }
            }
        } else if (active && !over) {
            if (inDropZone) {
                let newParent = choice.filter(obj => Object.values(obj)[0] !== Object.values(inDropZone)[0]);
                setChoice(newParent);
            }
        } else {
            console.log('no active or container found')
        }
    }

    const handleChoice = (value) => {

        if (currentItem.type.value === 'SINGLE_CHOICE') {
            setChoice([value])
        }
        if (currentItem.type.value === 'MULTIPLE_CHOICE') {
            const hasValue = choice.includes(value);
            let newValues;
            if (hasValue) newValues = choice.filter(i => i !== value);
            else newValues = [...choice, value];
            setChoice(newValues);
        }
    }

    const markForReview = async (value) => {
        // const choice = value;
        setIsMarked(value)
    }

    const handleShowAnswer = (value) => {
        setShowAnswer(value)
    }

    const handleReset = (id) => {
        setChoice([]);
        dispatch(practiceExamReset(id));
    }

    return (

        <Fragment>
            <Row className="no-gutters">
                <Col md={12}>
                    {examContent && (
                        <div className="content_bg pb-3">
                            <div className="exam_heading_with_action">
                                <ContentHeader title={`Exercise Questions (${currentPage} of ${examContent?.length||0})`} />
                            </div>
                        </div>
                    )}

                    {loading && <Loader />}
                    {examContent && !loading && !examContent.is_end ? (
                        <div className="content_bg pb-3">
                            <div className="c-card-bg py-3 m-3">
                                <Question item={currentItem} handleChoice={handleChoice} value={choice} disabled={isLocked} onDragEnd={handleDragEnd} />
                            </div>

                            <ExerciseFooter nextPrevPage={nextPrevPage} onDragEnd={handleDragEnd} paginate={paginate} totalItems={question} itemPerPage={itemPerPage} currentPage={currentPage}
                                examtype={examtype} userExamId={examContent ? examContent.id : null} markForReview={markForReview} handleShowAnswer={handleShowAnswer} showAnswer={showAnswer} marked={isMarked} id={match.id} handleReset={() => handleReset(examContent.id)} disabled={isLocked} isMarkForReviewBreak={isMarkForReviewBreak} break_time={examContent.break_time} showSpecialModal={showSpecialModal} />

                            {showAnswer && (
                                <div className="transition-1 mt-5 mb-5">
                                    <ShowExerciseAnswer item={currentItem} showAnswer={showAnswer} />
                                </div>
                            )}
                        </div>
                    ) : ''}
                </Col>
            </Row>
        </Fragment>
    )
}

export default ExerciseForm
