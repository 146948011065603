import {
    GET_CONTENT_REQUEST,
    GET_CONTENT_SUCCESS,
    GET_CONTENT_FAIL,
    GET_COURSE_CONFIG_FAIL,
    GET_COURSE_CONFIG_SUCCESS,
    GET_COURSE_CONFIG_REQUEST
} from '../constants/courseConstansts'
import { returnErrors } from './messageActions';
import axios from 'axios';
import { BASEURL } from '../utils/config';

import { _axios } from './axios/axios_conf'

export const getCourseContent = (lessonID, type) => (dispatch, getState) => {
    dispatch({ type: GET_CONTENT_REQUEST })

    _axios.get(`/lesson-topics/${lessonID}/${type}`)
        .then(res => {
            dispatch({
                type: GET_CONTENT_SUCCESS,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch({ type: GET_CONTENT_FAIL })

            dispatch(returnErrors(err.response.data.errors, err.response.status))
        })
}

export const getCouseLessons = (courseID) => (dispatch, getState) => {
    dispatch({ type: GET_CONTENT_REQUEST })

    _axios.get(`/course-lessons/${courseID}`)
        .then(res => {
            dispatch({
                type: GET_CONTENT_SUCCESS,
                payload: res.data.data.lessons.map(obj => obj.topics)
            })
        })
        .catch(err => {
            dispatch({ type: GET_CONTENT_FAIL })

            dispatch(returnErrors(err.response.data.errors, err.response.status))
        })
}

export const getCourseConfig = (courseId) => (dispatch, getState) => {
    dispatch({ type: GET_COURSE_CONFIG_REQUEST })
    _axios.get(`/config/${courseId}`)
        .then(res => {

            dispatch({
                type: GET_COURSE_CONFIG_SUCCESS,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch({ type: GET_COURSE_CONFIG_FAIL })
            dispatch(returnErrors(err.response.data.errors, err.response.status))
        })
}