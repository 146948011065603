import React, { Fragment } from 'react';
import ContentHeader from "../../content_header/ContentHeader"
import Table from '../../table/Table';
import {tableData} from './TableData';

const ResutByDomain = ({categories}) => {
    const data = [
        {
            "id": 1,
            "domain_name": "People",
            "number_of_question": "30",
            "correct_answer": "25",
            "correct_percentage": "30%",
            "rating": "ok",
            'result': 'Pass',

        },
        {
            "id": 1,
            "domain_name": "People",
            "number_of_question": "30",
            "correct_answer": "25",
            "correct_percentage": "30%",
            "rating": "ok",
            'result': 'Pass',

        },
    ]


    return (
        <Fragment>
            
            <div className="content_bg mt-3">
                <ContentHeader title="Result By Domain" />
            
                <Table data={categories && categories.length ? categories : []} cols={tableData()} />
            </div>



        </Fragment>
    )
}

export default ResutByDomain
