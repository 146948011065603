import React from 'react';

import CourseMetarial from "../../components/layout/card/course/CourseMetarialCardTwo";
import {faUniversity, faVideo, faTasks} from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from 'react-bootstrap';

function Home({match}) {
    return (
        <div className="mt-3">
            <Row className="no-gutters">
                <Col md={4}>
                    <div className="p-3">
                        <CourseMetarial
                            icon={faUniversity} 
                            title="Course List" 
                            courseId={match.params.id} 
                            link={`/course/topic/${match.params.id}`}
                        />
                    </div>
                </Col>
                <Col md={4}>
                    <div className="p-3">
                        <CourseMetarial
                            icon={faVideo} 
                            title="Video Tutorial" 
                            courseId={match.params.id} 
                            link={`/course/topic/${match.params.id}`}
                        />
                    </div>
                </Col>
                <Col md={4}>
                    <div className="p-3">
                        <CourseMetarial
                            icon={faTasks} 
                            title="Exam List" 
                            courseId={match.params.id} 
                            link={`/course/topic/${match.params.id}`}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Home
