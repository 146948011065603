import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropDownInput from '../../../components/layout/input/drop_down/DropDownInput';
import ContentHeader from "../../../components/layout/content_header/ContentHeader";
import Loader from '../../../components/helpers/Loader';

import { Col, Row, Button, Form } from 'react-bootstrap'

import { getCourseConfig } from '../../../actions/courseActions'
import { getExamList } from '../../../actions/examActions'


const ExamSelect = ({ history, match }) => {
    const [course] = useState(match.params.id);
    
    
    const [selectedcategory, setSelectedCategory] = useState('');
    const [subCategories,setSubCategories] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState('')
    
    
    const [examtype, setExamType] = useState('PRACTICE')
    const [exam, setExam] = useState('')

    const dispatch = useDispatch();


    useEffect(() => {
        setSelectedCategory('');
        setSubCategories([]);
        setSelectedSubCategory('');
        dispatch(getCourseConfig(match.params.id));
        setExamType('')
        
    }, [match.params.id])

    const courseData = useSelector(state => state.course);
    const courseExamList = useSelector(state => state.exam.examList);
    const { courseConfig, loading } = courseData
    
    const handleExamTypeDropdown = (value) => {
        if(value === 'MAIN'){
            dispatch(getExamList(match.params.id));
        }
        setExamType(value);
    };

    const handleCategoryDropdown = (value) => {

        setSelectedCategory(value);
        if(value !== ''){
            setSubCategories(courseConfig.categories.filter(c => c.id === value)[0].sub_categories)
        }else{
            setSubCategories([])
        }
    };
    
    const handleSubCategoryDropdown = (value) => {
        setSelectedSubCategory(value);
    };

    const handleExamDropdown = (value) => {
        setExam(value);
    };


    

    const submitHandler = (e) => {

        if(examtype == 'PRACTICE'){

            history.push({
                pathname: `/exam/exam-form/${selectedSubCategory}`,
                state: {
                    examtype:examtype,
                    method:'POST'
                },
            });

        }else{

            history.push({
                pathname: `/exam/exam-form/${exam}`,
                state: {
                    examtype:examtype,
                    method:'POST'
                },
            });

        }

    }



    return (
        <Fragment>
            {loading && <Loader />}
            {!loading && courseConfig && (
                <div className="content_bg pb-3">
                    <Row className="no-gutters">

                        <Col md={12} >
                            <ContentHeader title={`Exam Selection Form For ${courseConfig.title}`} sub_title="Select Your Exam" />
                            <div className="content-wrap-pad">
                                <Form onSubmit={submitHandler}>
                                    <DropDownInput
                                        data={[
                                            { id: "PRACTICE", name: 'Practice Mode' },
                                            { id: "MAIN", name: 'Exam Mode' },
                                        ]}
                                        value={examtype}
                                        placeholder='Select Exam Type'
                                        onChange={handleExamTypeDropdown}
                                    />

                                    {examtype === 'PRACTICE' ? (
                                        <Fragment>
                                            <DropDownInput
                                                data={courseConfig.categories}
                                                value={selectedcategory}
                                                placeholder='Select Course Category'
                                                onChange={handleCategoryDropdown}

                                            />

                                            <DropDownInput
                                                data={subCategories}
                                                value={selectedSubCategory}
                                                placeholder='Select Course Sub Category'
                                                onChange={handleSubCategoryDropdown}
                                            />

                                        </Fragment>

                                    ) : (
                                            <DropDownInput
                                                data={courseExamList}
                                                value={exam}
                                                placeholder='Select Exam'
                                                onChange={handleExamDropdown}
                                            />
                                        )}



                                    <Button className="btn c-btn-info rounded-0" type="submit">Run</Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>

            )}

        </Fragment>
    )
}

export default ExamSelect
