import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap'

const DropDownInput = ({data, placeholder, value, onChange}) => {
    
    
    const handleChange = (event) => {
        const {value} = event.target;
        onChange(value);
    };


    return (
        <Form.Group>
            <Form.Label className="font-weight-bold">{placeholder}</Form.Label>
            <Form.Control as="select" custom required onChange={handleChange} value={value}>
                <option value="">{placeholder}</option>
            {data && data.map((item, key) => (
                    <option
                        key={key}
                        value={item.id}>
                        {item.name}
                    </option>
                ))}
            </Form.Control>
      </Form.Group>
     
    )
}

DropDownInput.propTypes = {
    placeholder: PropTypes.string,
    data: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};

DropDownInput.defaultProps = {
    styleClass: '',
    placeholder: ''
};



export default DropDownInput
