import { createStore,applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers';

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null
const userCoursesFromStorage = localStorage.getItem('userCourses') ? JSON.parse(localStorage.getItem('userCourses')) : null


const initialState = {
    user : {userInfo: userInfoFromStorage,userCourses:userCoursesFromStorage},
}


const middleware = [thunk]

const store  = createStore(rootReducer,initialState,composeWithDevTools(applyMiddleware(...middleware)))

export default store