import React, { Fragment, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown'
import "./ShowAnswer.css";

const sampleData = [
    {
        "W": "E"
    },
    {
        "X": "A"
    },
    {
        "Y": "B"
    },
    {
        "Z": "C"
    },
    {
        "Zz": "D"
    }
];
const sampleData2 = [
    {
        "Q1": "A3"
    },
    {
        "Q2": "A2"
    },
    {
        "Q3": "A1"
    },
    {
        "Q4": "A5"
    },
    {
        "Q5": "A4"
    }
];

const ShowExerciseAnswer = ({ item, showAnswer }) => {

    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [correctAnswer, setCorrectAnswer] = useState(null);
    const [direction, setDirection] = useState("");
    const [questionType, setQuestionType] = useState({});
    const [explanation, setExplanation] = useState("");
    const [hint, setHint] = useState("");
    const [answer, setAnswer] = useState([]);
    const [reference, setReference] = useState("");
    const [explanationImage, setExplanationImage] = useState("");

    const getOptionDetails = (key, array) => {
        let result = array.filter(obj => {
            return obj.k === key
        })
        return result && result[0] && result[0].t || 'N/A';
    }

    useEffect(() => {
        if (item) {
            if (item.options.direction === 'left') {
                setAnswers(item.options.right);
                setQuestions(item.options.left);
            } else if (item.options.direction === 'right') {
                setAnswers(item.options.left)
                setQuestions(item.options.right)
            }
            setCorrectAnswer(/* item.id === "34554dab-0570-419f-99f2-011878e676bc" ? sampleData2 : item.id === "f2d9d350-e1ea-4d11-ba6a-a546c1825b8c" ? sampleData : item.correct_answer */ item.correct_answer);
            setDirection(item.options.direction);
            setQuestionType(item.type);
            setHint(item.hint);
            setAnswer(item.answer.keys)
            setExplanation(item.explanation);
            setReference(item.reference);
            setExplanationImage(item.explanation_image);
        }
    }, [item])

    return (
        <Fragment>

            <div className={`content-wrap-pad pickAnswerImg  mt-3`}>
                {questionType && questionType.value !== 'DRAG_DROP' && (
                    <>

                        {hint && (<p><b>Hint : </b> {<ReactMarkdown children={hint} allowDangerousHtml />}</p>)}
                        {answer.length && (<p className="mt-3 question_answer"><strong>Answer :</strong> {answer.join(", ")}</p>)}
                        {explanation && (<p className="answer_explanation"><strong>Explanation :</strong>   {<ReactMarkdown children={explanation} allowDangerousHtml />} </p>)}
                        {reference && (<p><b>Reference: </b>{<ReactMarkdown children={reference} allowDangerousHtml />}</p>)}
                        {explanationImage && (
                            <img src={explanationImage} alt="" />
                        )}
                    </>
                )}
                {questionType && questionType.value === 'DRAG_DROP' && (
                    <>

                        {hint && (<p><b>Hint : </b> {<ReactMarkdown children={hint} allowDangerousHtml />}</p>)}

                        <p><b>Correct answer:</b></p>
                        <div className="container mt-2 mb-5">
                            {correctAnswer.length !== 0 && correctAnswer.map((obj, idx) => {
                                const key = Object.keys(obj)[0];
                                return (
                                    <div className="answer_dragable_overlap_parent " key={idx}>
                                        {direction === 'left' ?
                                            <>
                                                <div className=" bg-gray border-bottom w-50 question_fixed">
                                                    <p className="m-0">
                                                        {`${key}. `}
                                                        {getOptionDetails(key, questions)}
                                                    </p>
                                                </div>
                                                <div className="bg-light-green right border-bottom w-50 answer_dragable_overlap" >
                                                    <p className="m-0">
                                                        {`${obj[key]}. `}
                                                        {getOptionDetails(obj[key], answers)}
                                                    </p>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="bg-light-green left border-bottom w-50 answer_dragable_overlap" >
                                                    <p className="m-0">
                                                        {`${obj[key]}. `}
                                                        {getOptionDetails(obj[key], answers)}
                                                    </p>
                                                </div>
                                                <div className=" bg-gray border-bottom w-50 question_fixed">
                                                    <p className="m-0">
                                                        {`${key}. `}
                                                        {getOptionDetails(key, questions)}
                                                    </p>
                                                </div>
                                            </>
                                        }
                                    </div>
                                )
                            })}
                        </div>

                        {explanation && (<p><b>Explanation: </b>{<ReactMarkdown children={explanation} allowDangerousHtml />}</p>)}
                        {reference && (<p><b>Reference: </b>{<ReactMarkdown children={reference} allowDangerousHtml />}</p>)}
                        {explanationImage && (
                            <img src={explanationImage} alt="" />
                        )}
                    </>
                )}
            </div>
        </Fragment>
    )
}

export default ShowExerciseAnswer
