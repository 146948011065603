import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Button, Form, Row, Col} from "react-bootstrap";
import { Link } from 'react-router-dom';

import "./ChangePassword.css";


import ContentHeader from "../../../../components/layout/content_header/ContentHeader";
import { userPasswordChange } from '../../../../actions/userActions';
import { createMessage } from '../../../../actions/messageActions';

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    
    
    const dispatch = useDispatch();


    const submitHandler = (e) => {
        e.preventDefault()
        
        if(newPassword !== '' && newPassword === confirmPassword){
            dispatch(userPasswordChange(oldPassword, newPassword))
            
        }else{
            dispatch(createMessage({ password_not_match: 'Password Not Match' }));
        }
        setConfirmPassword('');
        setOldPassword('');
        setNewPassword('')
        
    }


    return (
        <Fragment>
           <div className="content_bg py-3">
                <div className="change_password_header d-flex justify-content-between align-items-center flex-wrap">
                    <ContentHeader title="Change Password" sub_title="Change your account password" />
                    <div className="change_password_action_button content-wrap-pad">
                        <Button className="c-button-info mx-1 rounded-0" onClick={submitHandler}>Save Changes</Button>
                    </div>
                </div>
                <div className="change_password_form p-3 mt-3">
                    <Form>
                        <Form.Group controlId='pass'>
                            <Form.Row>
                                <Form.Label column lg={4} className="fs-6">
                                    Current Password
                                </Form.Label>
                                <Col lg={8}>
                                    <Form.Control className="login-input-form px-8 py-3 fs-6" type='password' placeholder='Current Password' value={oldPassword} onChange={(e) => setOldPassword(e.target.value)}></Form.Control>

                                </Col>
                                <p className="lead fs-6 mt-2 text-success">Should have at least one number, one uppercase and one lowercase character.Should have at least one special symbol($,@,#,%). Should be between 6 to 20 characters long.r digit between [0-9]</p>
                            </Form.Row>
                            
                            
                        </Form.Group>

                        <Form.Group controlId='pass'>
                            <Form.Row>
                                <Form.Label column lg={4} className="fs-6">
                                    New Password
                                </Form.Label>
                                <Col lg={8}>
                                    <Form.Control className="login-input-form px-8 py-3 fs-6" type='password' placeholder='New Password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)}></Form.Control>
                                </Col>
                            </Form.Row>
                        </Form.Group>
                        <Form.Group controlId='pass'>
                            <Form.Row>
                                <Form.Label column lg={4} className="fs-6">
                                    Confirm Password
                                </Form.Label>
                                <Col lg={8}>
                                    <Form.Control className="login-input-form px-8 py-3 fs-6" type='password' placeholder='Confirm Password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}></Form.Control>
                                </Col>
                            </Form.Row>
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </Fragment>
    )
}

export default ChangePassword
