
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faBan } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Row, Col, Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import "./QuestionReviewModal.css"
import Loader from '../../../../components/helpers/Loader';
const QuestionReviewModal = (props) => {

    let history = useHistory();
    const viewQuestion = (index) => {
        history.push({
            pathname: `/exam/exam-form/${props.userExamId}`,
            state: { examtype: props.examtype, method: 'POST', index: index },
        });
        // to={`/exam/exam-form/${props.userExamId}?index=${item.display}`}
        closeModal();
    }

    const closeModal = () => {
        props.onHide();
    }

    return (
        
        <Modal show={props.show}
        onHide={props.onHide} aria-labelledby="contained-modal-title-vcenter" className="">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="fs-6">
                    Question List
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid question-review-mod">
                <Container>
                    <Row>
                        {props.loading && <Loader />}
                        {props.reviewList && props.reviewList.length && props.reviewList.map((item, idx) => (
                            <Col md={10} key={idx} className="mx-auto">
                                <div className="content-wrap-pad">
                                    <Button onClick={() => viewQuestion(item.display)} className="question-item btn c-btn-secondary w-50 d-flex justify-content-around align-items-center mb-3">
                                        {props.examtype === 'PRACTICE' ? (
                                            <>
                                                <span className="mark-color">{item.mark_for_review && 'M'}</span>
                                                <span>{item.display}</span>
                                                {item.user_answer  && <FontAwesomeIcon icon={faCheck} className="check-color" />
                                                    || <FontAwesomeIcon icon={faBan} className="ban-color" />}

                                            </>


                                        ) :
                                            <>

<>
                                                <span className="mark-color">{item.mark_for_review && 'M'}</span>
                                                <span>{item.display}</span>
                                                {item.user_answer && <FontAwesomeIcon icon={faCheck} className="check-color" />
                                                    || <FontAwesomeIcon icon={faBan} className="ban-color" />}

                                                </>
                                                
                                                {/* <span className="mark-color">{item.mark_for_review && 'M'}</span>
                                                <span>{item.display}</span>

                                                {item.is_correct && item.user_answer !== "" && <FontAwesomeIcon icon={faCheck} className="check-color" />
                                                }

                                                {!item.is_correct && item.user_answer !== "" && <FontAwesomeIcon icon={faBan} className="deep-ban-color" />
                                                }


                                                {item.user_answer === "" && <FontAwesomeIcon icon={faBan} className="ban-color" />} */}
                                            </>

                                        }

                                    </Button>
                                </div>
                            </Col>

                        ))}

                    </Row>

                </Container>
            </Modal.Body >
            <Modal.Footer>
                <Button onClick={closeModal} className="btn c-btn-info rounded-0">Close</Button>
            </Modal.Footer>
        </Modal >
    )
}

export default QuestionReviewModal
