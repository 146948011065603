import {
    CREATE_MESSAGE,
    GET_ERRORS,
    CLEAR_MESSAGE,
    CLEAR_ERRORS
} from '../constants/messageConstants';

// Create Message
export const createMessage = (msg)  => (dispatch) => {
    dispatch({type: CREATE_MESSAGE,payload: msg});
    setTimeout(() => {
        dispatch({type: CLEAR_MESSAGE,payload: {} })  
    }, 3000); 
 
}

// Return Errors
export const returnErrors = (msg,status) => (dispatch) =>{
    dispatch({type: GET_ERRORS,payload: {msg,status}});
    setTimeout(() => {
        dispatch({type: CLEAR_ERRORS,payload: {} })  
    }, 3000); 
}


