import React,{ useState,useEffect,Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faAngleRight, faAlignLeft,faAngleLeft, faUser} from "@fortawesome/free-solid-svg-icons";
import { Navbar, Button, Nav } from "react-bootstrap";
import { logout } from '../../../actions/userActions'

import { useHistory } from "react-router-dom";

const NavBar = ({ collapsed, handleToggleSidebar, handleCollapsedChange, toggled }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
	const { userInfo } = user;

    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 768;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    const logoutHandler = () => {
        dispatch(logout())
    }


    return (
       
        <Fragment>
            <Navbar className={`${collapsed ? 'content-collpased' : 'content'} navbar custom_nav `} expand>

                <Button variant="dark" className={`${isMobile ? "block" : "d-none"} custom_bar_icon nav-menu-left`} onClick={() => handleToggleSidebar(!toggled)}>
                    <FontAwesomeIcon icon={faAlignLeft} /> 
                    {/* <h3 className="ml-2 mb-0">Home</h3> */}
                </Button>

                <div className={`${isMobile ? "d-none" : "block"}`}>
                    {/* <h3 className="mt-2 fs-24">Home</h3> */}
                </div>



                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto" navbar>
                        <Nav.Link href="#" className="nav_text text-hover-primary">
                            <FontAwesomeIcon icon={faUser} className="mr-1"/>
                            {userInfo.username}
                        </Nav.Link>
                        <Nav.Link href="#" className="nav_text text-hover-primary" onClick={logoutHandler}>
                            <FontAwesomeIcon icon={faSignOutAlt} className="mr-1"/>
                            Logout

                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <div className={`${isMobile ? "d-none" : "block"} sidenav-btn`}>
                <Button className={`${collapsed ? 'sidenav-btn-possition-collapsed' : 'sidenav-btn-possition'} btn c-btn-primary `}  onClick={() => handleCollapsedChange(!collapsed)}>
                    {/* <FontAwesomeIcon icon={`${collapsed ? {} : {faAngleLeft}}`} /> */}
                    {collapsed ? (
                        <FontAwesomeIcon icon={faAngleRight} />
                    ) : <FontAwesomeIcon icon={faAngleLeft} />}
                    
                </Button>
            </div>
        </Fragment>
    )
}

export default NavBar
