import { USER_LOGIN_REQEST, USER_LOGIN_SUCEESS, USER_LOGOUT, USER_LOGIN_FAIL, SET_CURRENT_USER, USER_LOGOUT_FAIL, USER_DETAILS_REQUEST, USER_DETAILS_SUCCESS, USER_DETAILS_FAIL,PASSWORD_CHANGE_REQUEST,PASWORD_CHANGE_SUCCESS,PASSWORD_CHANGE_FAIL } from '../constants/userConstants';
import isEmpty from '../utils/is_empty';

const initialState = {
    userInfo: {},
    userDetails: null,
    userCourses: [],
    loading: false,
    isAuthenticated: false,
    profile: {}

}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN_REQEST:
        case USER_DETAILS_REQUEST:
        case PASSWORD_CHANGE_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case USER_LOGIN_SUCEESS:
            return {
                ...state,
                userInfo: action.payload.userInfo,
                isAuthenticated: !isEmpty(action.payload.userInfo),
                userCourses: action.payload.userCourses,
                loading: false,
            }
        case SET_CURRENT_USER:
            return {
                ...state,
                userInfo: action.payload
            }
        
        case PASSWORD_CHANGE_FAIL:
        case USER_LOGIN_FAIL:
        case PASWORD_CHANGE_SUCCESS:
            return {
                ...state,
                loading: false,
            }

        case USER_DETAILS_SUCCESS:
            return {
                ...state,
                userDetails: action.payload,
                loading: false,
            }   
        case USER_DETAILS_FAIL:
            return {
                ...state,
                userDetails: null,
                loading: false,
            }
        case USER_LOGOUT_FAIL:
        case USER_LOGOUT:
            return {
                ...state,
                userInfo: null,
                isAuthenticated: false,
                userDetails: null,
                userCourses: null,
                loading: false
            }

        default:
            return state
    }
}