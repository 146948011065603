import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faVideo, faFileImage } from "@fortawesome/free-solid-svg-icons";

import ContentHeader from '../../../components/layout/content_header/ContentHeader';
import ImageViewer from '../../../components/layout/image/ImageViewer';

import "./CourseTopic.css";
import Loader from '../../../components/helpers/Loader';
import { getCourseContent, getCouseLessons } from '../../../actions/courseActions';
import { checkExtension } from '../../../utils/checkExtension'

import NoData from "../../../components/layout/card/blank_page/BlankPage";
import VideoPlayer from '../../../components/layout/video_player/VideoPlayer';
import VideoSection from '../../../components/layout/video_player/VideoSection';
import { Row, Col } from 'react-bootstrap';

import { Collapse, Space } from 'antd';
import ExerciseForm from '../../../components/layout/exercise/ExerciseForm';
import FlashCardModal from '../../../components/helpers/FlashCardModal';

const { Panel } = Collapse;

const CourseTopic = ({ match, location }) => {

    const dispatch = useDispatch();

    const type = match.params.type

    const course = useSelector(state => state.course)
    const { loading, courseContent = {} } = course

    const [activeKey, setActiveKey] = useState([])


    useEffect(() => {
        if (type === 'video') {
            dispatch(getCourseContent(match.params.id, type))
        }
        if (type === 'document') {
            dispatch(getCouseLessons(match.params.id))
        }
    }, [match.params.id, type])

    const ifObjectAndEmpty = yourVariable => {
        if (
            typeof yourVariable === 'object' &&
            !Array.isArray(yourVariable) &&
            yourVariable !== null
        ) {
            return true
        } else {
            return false
        }
    }

    const onChange = (key) => {
        setActiveKey(key)
    };

    return (

        <div className="content_bg pb-3">
            {loading && <Loader />}
            {
                type === 'video'
                    ?
                    <>
                        {!loading && courseContent && ifObjectAndEmpty(courseContent) && (<ContentHeader title={`Course Content For ${courseContent.name}`} sub_title="Course Materials" />)}
                        {(!loading && courseContent && ifObjectAndEmpty(courseContent) && courseContent.topics && courseContent.topics.length) ? courseContent.topics.map((item, idx) => (
                            <div key={idx} className="content-wrap-pad mb-3 w-100 custom_course_topic_card">
                                {match.params.type === 'video' &&
                                    (
                                        <>
                                            <Collapse onChange={onChange} activeKey={activeKey}>
                                                <Panel
                                                    style={{
                                                        backgroundColor: activeKey.find(x => x === idx.toString())
                                                            ? '#cfecd1' : ''
                                                    }}
                                                    header={item.title} key={idx}>
                                                    <Collapse >
                                                        <Panel header="Video" key="1">
                                                            {item.contents.filter((cnt, indx) => {
                                                                return checkExtension(cnt.extension) === 'VIDEO';
                                                            }
                                                            ) && <><VideoSection item={item} key={idx} /></>}
                                                        </Panel>
                                                    </Collapse>
                                                    <br />
                                                    <Collapse>
                                                        <Panel header="Exercise" key="2">
                                                            {
                                                                item.exercises.length
                                                                    ?
                                                                    <ExerciseForm match={item.exercises} key={idx} history={undefined} />
                                                                    : "Not found"
                                                            }
                                                        </Panel>
                                                    </Collapse>
                                                    <br />
                                                    <Collapse>
                                                        <Panel header="Flash Cards" key="2">
                                                            <Space size={[8, 16]} wrap>
                                                            {item?.flashcards?.length && item.flashcards.map((flashcard)=>
                                                            <FlashCardModal title={flashcard.title} description={flashcard.description} key={flashcard.id}/>
                                                            )
                                                            ||
                                                            "Not Found"}
                                                            </Space>
                                                        </Panel>
                                                    </Collapse>
                                                </Panel>
                                            </Collapse>
                                        </>
                                    )
                                }
                            </div>
                        ))
                            :
                            (!loading && (!courseContent || !ifObjectAndEmpty(courseContent))) ?
                                <NoData />
                                :
                                null
                        }
                    </>
                    :
                    <>
                        <ContentHeader title={`Course Content`} sub_title="Course Materials" />
                        {!loading && courseContent && courseContent.length > 0 ? courseContent.map((items, index) => (
                            items.map((item, idx) => (
                                <div key={idx} className="content-wrap-pad mb-3 w-100 custom_course_topic_card">
                                    {((item.contents.filter((cnt, indx) => checkExtension(cnt.extension) === 'DOCUMENT').length) && match.params.type === 'document') ?
                                        (
                                            <>
                                                <div className="custom_course_topic_card_head">
                                                    <p style={{ fontWeight: 'bold', color: '#44c5bd' }}>{item.title}</p>
                                                </div>
                                                <div className="custom_course_topic_card_body">
                                                    <Row className="">
                                                        {item.contents.map((cnt, idx) => (
                                                            <Col lg={3} md={4} sm={8} className="mb-3" key={idx}>
                                                                <div className="" key={idx}>
                                                                    <div className="fs-6 mb-2  pointer cp">

                                                                        {checkExtension(cnt.extension) === 'DOCUMENT' && (
                                                                            <>
                                                                                <FontAwesomeIcon icon={faFilePdf} className="menu_item_icon_color mr-1 pt-1" />
                                                                                <a href={cnt.file} target="_blank">{cnt.title}</a>
                                                                            </>
                                                                        )}
                                                                        {checkExtension(cnt.extension) === 'IMAGE' && (
                                                                            <>
                                                                                <div className="document_image mb-2">
                                                                                    <ImageViewer img={cnt.file} alt={cnt.title} />
                                                                                    <p className="text-left lead">{cnt.title}</p>
                                                                                </div>

                                                                            </>
                                                                        )}

                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </div>
                                            </>
                                        )
                                        :
                                        null
                                    }
                                </div>
                            ))
                        ))
                            :
                            (!loading && (!courseContent || !ifObjectAndEmpty(courseContent))) ?
                                <NoData />
                                :
                                null
                        }
                    </>
            }
        </div>
    )
}

export default CourseTopic
