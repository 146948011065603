import React, { Fragment, useEffect } from 'react';
import ContentHeader from "../../content_header/ContentHeader"

import { calculatePercentage } from '../../../../utils/func';



const ResultSheet = ({examDetails}) => {
    return (
        <Fragment>

            <div className="content_bg">
                <ContentHeader title="Result Sheet" sub_title="Showing here your recent participant exam results" />
                <table className="table table-hover">
                    <tbody>
                        <tr>
                            <th>Course Name</th>
                            <td>{examDetails.course_title}</td>

                        </tr>
                        <tr>
                            <th>Exam Name</th>
                            <td>{examDetails.name}</td>

                        </tr>
                        <tr>
                            <th>Score</th>
                            <td>{calculatePercentage(examDetails.total_question,examDetails.total_correct_answered)}% (Passing Score {examDetails.pass_mark})</td>

                        </tr>
                        <tr>
                            <th>Total Question</th>
                            <td>{examDetails.total_question}</td>

                        </tr>
                        <tr>
                            <th>Total Answered</th>
                            <td>{examDetails.total_answered}</td>

                        </tr>
                        <tr>
                            <th>Total Correct Answer</th>
                            <td>{examDetails.total_correct_answered}</td>

                        </tr>
                    </tbody>
                </table>
            </div>





        </Fragment>
    )
}

export default ResultSheet
