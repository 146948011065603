import { 
    
    GET_FAQ_REQUEST,
    GET_FAQ_SUCCESS,
    GET_FAQ_FAIL,
   
} from '../constants/settingsConstants'
import { returnErrors } from './messageActions';
import { BASEURL } from '../utils/config';
import { _axios } from './axios/axios_conf'

export const getFaqList = () => (dispatch, getState) => {
    dispatch({ type: GET_FAQ_REQUEST })
    _axios.get(`/faqs/`)
        .then(res => {
            dispatch({
                type: GET_FAQ_SUCCESS,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch({ type: GET_FAQ_FAIL })
            dispatch(returnErrors(err.response.data.errors, err.response.status))
        })
}

