import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ResultSheet from '../../../components/layout/exam/result_sheet/ResultSheet';
import ResultByDomain from '../../../components/layout/exam/result_by_domain/ResutByDomain';
import ExamQuestionList from '../../../components/layout/exam/exam_question_list/ExamQuestionList';


import { userExamDetails } from '../../../actions/examActions';
import Loader from '../../../components/helpers/Loader';



const ExamDetails = ({match}) => {
    

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(userExamDetails(match.params.id))
    }, [])

    const loading = useSelector(state => state.exam.loading);
    const examDetails = useSelector(state => state.exam.examDetails);
    
    
    return (
        <Fragment>
            {examDetails && !loading ? (
                <div className="mr-2">
                    <ResultSheet examDetails={examDetails}/>

                    <ResultByDomain categories={examDetails.categories} />

                    <ExamQuestionList examDetails = {examDetails}/>
                </div>
            ): <Loader />
            }
        </Fragment>
    )
}

export default ExamDetails
