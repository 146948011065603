import React, { Fragment, useState } from 'react';
import { Modal, Button, Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faBan } from "@fortawesome/free-solid-svg-icons";
import "./ExamQuestionList.css";


import QuestionDetailsModal from '../../exam/question_details_modal/QuestionDetailsModal';
import ExamDetails from '../../../../screens/exam/exam_details/ExamDetails';
import { set } from 'lodash';

const ExamQuestionList = ({ examDetails }) => {
    const [modalShow, setModalShow] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [index, setIndex] = useState('');
    const examQuestionList = examDetails.user_exam_questions;


    const nextPrevPage = (number) => {
        let currentQestion = examQuestionList && examQuestionList.length ? examQuestionList.find((item) => {
            return item.display === number
        }) : [];
        setCurrentItem(currentQestion)
    }

    const firstItemDisplay = examQuestionList && examQuestionList.length && examQuestionList[0].display;
    const lastItemDisplay = examQuestionList && examQuestionList.length && examQuestionList[examQuestionList.length - 1].display;

    return (
        <Fragment>
            
            <div className="content_bg mt-3">

                <div className="d-flex justify-content-start align-items-center flex-wrap exam-details-review">
                    {examQuestionList && examQuestionList.length && examQuestionList.map((item, idx) => (
                        <div key={idx}>
                            <Button
                                className="width-12 btn c-btn-secondary d-flex justify-content-around align-items-center mb-2 mx-1"
                                onClick={() => {
                                    setModalShow(true);
                                    setCurrentItem(item);
                                    setIndex(idx);
                                }}
                            >
                                <span className="mark-color">{item.mark_for_review && 'M'}</span>
                                <span>{item.display}</span>

                                {item.is_correct && item.user_answer !== "" && <FontAwesomeIcon icon={faCheck} className="check-color" />
                                }

                                {!item.is_correct && item.user_answer !== "" && <FontAwesomeIcon icon={faBan} className="deep-ban-color" />
                                }


                                {item.user_answer === "" && <FontAwesomeIcon icon={faBan} className="ban-color" />}
                            </Button>


                        </div>


                    ))}

                    <QuestionDetailsModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        content={currentItem}
                        exam={examDetails.name}
                        firstItemDisplay={firstItemDisplay}
                        lastItemDisplay={lastItemDisplay}
                        nextPrevPage={nextPrevPage}
                    />

                </div>

            </div>
        </Fragment>
    )
}

export default ExamQuestionList
