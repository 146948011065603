import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ContentHeader from "../../../../components/layout/content_header/ContentHeader";

import Table from "../../../../components/layout/table/Table";
import { SubscriptionData } from "./SubscriptionData";
import { tableData } from "./SubscriptionCollumn";

import Loader from '../../../../components/helpers/Loader'
import { getUserDetails } from '../../../../actions/userActions';

const AccountInfo = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserDetails())
    }, [])

    const user = useSelector(state => state.user)
    const { loading, userDetails } = user;

    return (
        <Fragment>
            {/* {loading && <Loader />} */}
            {userDetails &&
                <div className="content_bg ph-3">
                    <div className="change_password_header d-flex justify-content-between align-items-center flex-wrap">
                        <ContentHeader title="Account Information" sub_title="You can see your subscriptions here." />
                    </div>
                    <div className="subscription-area content-wrap-pad pt-5">
                        <h5>Subscription List</h5>

                        <div className="list-table mt-3">
                            <Table data={userDetails && userDetails.subscriptions && userDetails.subscriptions.length ? userDetails.subscriptions : []} cols={tableData()} />
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default AccountInfo
