export function handelRightClick(event) {
    event.preventDefault();
}

export function keydownHandler(event){
    if(event.keyCode===80 && event.ctrlKey){
        event.preventDefault();
        return false;
    }
}
