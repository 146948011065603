import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Row, Col, Alert} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/helpers/Loader';
import { login } from '../../../actions/userActions';
import { handleCopy } from '../../../utils/func'
import "./Login.css";
import loginBg from "../../../assets/images/exam.jpg";

import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import Logo from "../../../assets/images/final-feedback-full-logo-raw.png";


import {APP_VERSION} from '../../../utils/config';

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const Login = ({ location,history }) => {
    
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const dispatch = useDispatch()

    const user = useSelector(state => state.user)
    const { loading, userInfo } = user

    const redirect = location.search ? location.search.split('=')[1] : '/'
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };



    useEffect(() => {
        if (userInfo) {
           history.push(redirect) 
        }
    },[history, userInfo, redirect])

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(login(username, password));
        setUsername('');
        setPassword('');
    }
    



    return (
        
        <Fragment>
            <div className="login-content">
                <Row className="no-gutters">
                    <Col md={12}>
                        <div className="login-container">
                            <div className="login-100">
                                {/* <Alert variant="danger">
                                    <Alert.Heading>Downtime Notice</Alert.Heading>
                                    <p>Please be informed that we shall upload new question in the application (Exam simulator) and remove all previous data of  Exam simulator, content will remain suspended during the below mentioned period. Customers will not be able to do exam during that time. All previous data will be removed.</p>
                                    <p className="mb-0" style={{fontWeight:500}}>
                                        Downtime details:
                                    </p>
                                    <ul style={{fontWeight:500}}>
                                        <li>Start Time  : April 24, 2023 at 12:30:00 AM</li>
                                        <li>End Time    : April 27, 2023 at 01:30:00 AM</li>
                                    </ul>
                                    <p>We sincerely regret the inconvenience. Thanks for your understanding and cooperation. If you have any queries, don't hesitate to get in touch with our operation team at <span style={{color:'#007bff'}}>01885855555</span> or email us at <a href="mailto:ruhul@3pcltd.com">ruhul@3pcltd.com</a></p>
                                </Alert> */}
                                <div className="wrap-login100">
                                    <div className="login100-form">
                                        {loading && <Loader />}
                                        <Form onSubmit={submitHandler} className="form-100">
                                            <div className="login-logo">
                                                <img src={Logo} alt="logo.png"/>
                                            </div>
                                            <Form.Group controlId='username' className="wrap-input100 rs1-wrap-input100 validate-input m-b-20">
                                                <Form.Control className="login-input-form px-8 py-3 fs-6 input100" required type='text' placeholder='Username' value={username} onChange={(e) => setUsername(e.target.value)}></Form.Control>
                                                <span className="focus-input100"></span>
                                            </Form.Group>

                                            <Form.Group controlId='pass'  className="wrap-input100 rs2-wrap-input100 validate-input m-b-20 ">
                                                <Form.Control className="login-input-form px-8 py-3 fs-6 input100" required type={passwordShown ? "text" : "password"} placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)}></Form.Control>
                                                <i onClick={togglePasswordVisiblity}>
                                                    
                                                    {passwordShown ? eye : eyeSlash}
                                                </i>
                                                <span className="focus-input100"></span>
                                                
                                                {/* <i onClick={togglePasswordVisiblity}>{eye}</i> */}
                                                
                                            </Form.Group>
                                           

                                            <div className="w-100">
                                                <Button type='submit' className="btn-block login100-form-btn rounded-0">Sign In</Button>
                                            </div>
                                        </Form>
                                    </div>
                                    <div className="login100-more" style={{backgroundImage: `url(${loginBg})`}}>
                                        <div className="div-footer">
                                            <div >
                                                <h4>Disclaimer</h4>
                                                <h5>"PMI", "PMP", "PgMP", "CAPM", “PMI-ACP”,</h5>
                                                <h5>"PMBOK Guide" and the PMI Registered</h5>
                                                <h5>Education Provider Logo are trademarks of</h5>
                                                <h5>Project Management Institute, Inc.</h5>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Fragment>

    )
}

export default Login
