import React, { useState, Fragment, useEffect, useRef } from 'react';
import { Prompt } from 'react-router'
import {
    useLocation,
    useHistory,
    Redirect
} from "react-router-dom";
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ContentHeader from "../../../components/layout/content_header/ContentHeader";
import QuestonFooter from "../../../components/layout/question/question_footer/QuestonFooter";
import QuestionHeader from "../../../components/layout/question/question_header/QuestionHeader";
import Question from '../../../components/layout/question/question/Question';
import ShowAnswer from '../../../components/layout/question/show_answer/ShowAnswer';
import { getExamContent, removeExamContent, inProgressExam, questionAnswerSubmit, questionAnswerSubmitOnlyMarkForReview, continueExam, breakExam, markForReviewBreak } from '../../../actions/examActions';
import { setQuestionMarkForReview, practiceExamReset } from '../../../actions/examActions';
import { endExam } from '../../../actions/examActions';
import CountDownTimer from '../../../components/layout/count_down/CountDownTimer';

import { dummyQusetions, sampleData } from './mockData'

import Loader from '../../../components/helpers/Loader';
import ExamBreakModal from '../../../components/layout/exam/exam_break_modal/ExamBreakModal';
console.log("CountDownTimer==========>", CountDownTimer)
const ExamForm = ({ match, history }) => {
    const [choice, setChoice] = useState([]);
    const [isMarked, setIsMarked] = useState(false);
    // const[modalState, setModalState]=useState({
    //     show: false,
    //     flag: true,
    // })

    const [modalState, setModalState] = useState(false)
    // const [answerSubmitCount, setAnswerSubmitCount] = useState(0)


    const [pauseCount, setPauseCount] = useState(false);
    const [resumeCount, setResumeCount] = useState(false);
    const [onComplete, setOnComplete] = useState(true);

    const [examId, setExamId] = useState('');
    const [timeout, setTimeout] = useState(false);
    const [isMarkForReviewBreak, setIsMarkForReviewBreak] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [showAnswer, setShowAnswer] = useState(false);
    const [itemPerPage] = useState(1);
    const [isLocked, setIsLocked] = useState(false);

    const location = useLocation();
    const { examtype, method } = location.state || {};


    const examContentRef = useRef();

    // Get Current Posts
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;

    const dispatch = useDispatch();

    const examContent = useSelector(state => state.exam.examContent);
    const loading = useSelector(state => state.exam.loading);

    const [showSpecialModal, setShowSpecialModal] = useState(false);



    if (location.state && location.state.index && currentPage !== location.state.index) {
        setCurrentPage(location.state.index);
        const state = { ...history.location.state };
        delete state.index;
        history.replace({ ...history.location, state });
    }





    useEffect(() => {
        if (!location.state) {
            history.push("/");
        }

        setChoice([]);
        setIsMarked(false);
        if (match.params.examId && method && method === 'POST' && examtype) {
            dispatch(getExamContent(match.params.examId, examtype));
        }
        else if (match.params.examId && method && method === 'GET') {
            dispatch(inProgressExam(match.params.examId));
        }
        else {
            dispatch(removeExamContent())
        }
    }, [])

    useEffect(() => {
        return () => {
            handleEndExam()
        }
    }, [])

    useEffect(() => {
        examtype && examtype == 'MAIN' &&
            window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', handleEndExam)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', handleEndExam)
        }
    }, [])

    //let examBreak = NULL;

    useEffect(() => {
        examContentRef.current = examContent && examContent.id;

        if (examtype && examtype == 'MAIN' && examContent && examContent.exam_break) {
            let examBreak = examContent.exam_break.replace(/\s/g, '');
            let breakArr = examBreak.split(',');
            if (breakArr.includes((examContent.total_answered).toString())) {
                setModalState(true)
            }

        }

    }, [examContent])


    const alertUser = e => {
        e.preventDefault()
        e.returnValue = ''
    }

    const handleEndExam = async () => {
        if (!examContentRef.current) return;
        if (examtype && examtype === 'MAIN') {
            try {
                dispatch(endExam(examContentRef.current, history));
            } catch (error) {

            }
        } else {
            dispatch(removeExamContent());
        }
    }

    const handleTimeOut = () => {
        let id = examContent && examContent.id;
        setTimeout(true);
        // console.log("Exam ID=======>", id)
        dispatch(endExam(id, history));
    }




    const qusetions = (examContent && examContent.user_exam_questions && examContent.user_exam_questions.length) ? examContent.user_exam_questions : examContent && examContent.practice_exam_questions && examContent.practice_exam_questions.length ? examContent.practice_exam_questions /*sampleData*/ : dummyQusetions;






    const currentItem = qusetions.slice(indexOfFirstItem, indexOfLastItem)[0];



    useEffect(() => {
        setChoice([])
        setIsLocked(false);
        if (!currentItem) return;
        setIsMarked(currentItem.mark_for_review)
        if (currentItem && currentItem.user_answer && currentItem.user_answer !== '') {
            setChoice(currentItem.type.value === 'DRAG_DROP' && currentItem.user_answer || currentItem.user_answer.split(','));
        }
        if (examContent && examContent.lock_questions && examContent.lock_questions.includes(currentItem.id)) {
            setIsLocked(true);
        }
    }, [currentItem])

    const paginate = (number) => {
        setCurrentPage(number);
    }

    const nextPrevPage = async (number, examPauseModal, forSubmit) => {
        setShowAnswer(false);

        if (examPauseModal === false) {

            setModalState(false);
        }

        if (!isLocked) {
            if (choice.length) {
                if (isMarkForReviewBreak) {
                    try {
                        await dispatch(questionAnswerSubmitOnlyMarkForReview(currentItem.type.value, currentItem.id, choice, isMarkForReviewBreak))
                    } catch (error) {

                    }
                } else {
                    try {
                        await dispatch(questionAnswerSubmit(currentItem, choice, examtype, isMarked))
                    } catch (error) {

                    }

                }

                // setChoice([])
            } else {
                if (!isMarkForReviewBreak) {
                    try {
                        await dispatch(setQuestionMarkForReview(currentItem.id, examtype, isMarked))
                    } catch (error) {

                    }
                }

                // setIsMarked(false);
            }
        }
        setCurrentPage(number);
        if (number) {
            paginate(number);
        }
        if(forSubmit){
            history.push('/exam-list');
            // alert(forSubmit)
        }
    }

    const handleDragEnd = (event) => {
        const checkKeyPresenceInArray = (array, key) => array.some(obj => Object.keys(obj).includes(key));
        const { over, active } = event;
        let inDropZone = choice.find(obj => Object.values(obj)[0] === active.id)
        if (over && active) {
            if (checkKeyPresenceInArray(choice, over.id)) {
                alert('Please remove current answer to add new one');
            } else {
                if (inDropZone) {
                    console.log(Object.values(inDropZone)[0])
                    let newParent = choice.filter(obj => Object.values(obj)[0] !== Object.values(inDropZone)[0]);
                    newParent.push({ [over.id]: active.id });
                    console.log(choice, newParent)
                    setChoice(newParent);
                } else {
                    setChoice(prev => [...prev, { [over.id]: active.id }]);
                }
            }
        } else if (active && !over) {
            if (inDropZone) {
                let newParent = choice.filter(obj => Object.values(obj)[0] !== Object.values(inDropZone)[0]);
                setChoice(newParent);
            }
        } else {
            console.log('no active or container found')
        }
    }

    const handleChoice = (value) => {

        if (currentItem.type.value === 'SINGLE_CHOICE') {
            setChoice([value])
        }
        if (currentItem.type.value === 'MULTIPLE_CHOICE') {
            const hasValue = choice.includes(value);
            let newValues;
            if (hasValue) newValues = choice.filter(i => i !== value);
            else newValues = [...choice, value];
            setChoice(newValues);
        }
    }

    const markForReview = async (value) => {
        // const choice = value;
        setIsMarked(value)
    }

    const handleShowAnswer = (value) => {
        setShowAnswer(value)
    }

    const handleReset = (id) => {
        setChoice([]);
        dispatch(practiceExamReset(id));
    }
    const initiateBreak = async () => {
        try {
            await dispatch(breakExam(examContent && examContent.id || null));
            setResumeCount(false);
            setPauseCount(true);
            setShowSpecialModal(true);
        } catch (error) {
            throw error;
        }

    }


    const cancelBreak = () => {
        setIsMarkForReviewBreak(false);
        setShowSpecialModal(false);

        setModalState(false);

        setPauseCount(false);
        setResumeCount(true);

        dispatch(continueExam(examContent && examContent.id || null));


    }

    const reviewBreak = () => {
        setCurrentPage(1)

        setModalState(false);
        dispatch(markForReviewBreak(examContent && examContent.id || null)).then(res => {
            setIsMarkForReviewBreak(true)
        }).catch(err => {
            dispatch(continueExam(examContent && examContent.id || null));
            setIsMarkForReviewBreak(false)
        });

    }

    const backFormreview = () => {
        setCurrentPage(1)
        setIsMarkForReviewBreak(false);
        setShowSpecialModal(false);
        setModalState(false);

        dispatch(continueExam(examContent && examContent.id || null));
    }

    return (

        <Fragment>
            <Row className="no-gutters">
                <Col md={12}>
                    {examContent && (
                        <div className="content_bg pb-3">
                            <div className="exam_heading_with_action">
                                <ContentHeader title={`${examContent ? examContent.course_title : 'PMP'} (${examContent && examContent.name ? examContent.name : examContent.sub_category})`} sub_title={`Question ${currentPage} Of ${qusetions.length}`} />
                                {examtype && examtype == 'MAIN' &&
                                    <CountDownTimer duration={examContent.duration_time} examId={examContent && examContent.id} pauseCount={pauseCount} resumeCount={resumeCount} text={'Exam Time'} onComplete={handleTimeOut}/>
                                }
                            </div>
                        </div>
                    )}


                    {loading && <Loader />}
                    {examContent && !loading && !examContent.is_end ? (
                        <div className="content_bg pb-3">
                            <div className="c-card-bg py-3 m-3">
                                <Question item={currentItem} handleChoice={handleChoice} value={choice} disabled={isLocked} onDragEnd={handleDragEnd} />
                            </div>

                            <QuestonFooter nextPrevPage={nextPrevPage} onDragEnd={handleDragEnd} paginate={paginate} totalItems={qusetions} itemPerPage={itemPerPage} currentPage={currentPage}
                                examtype={examtype} userExamId={examContent ? examContent.id : null} markForReview={markForReview} handleShowAnswer={handleShowAnswer} showAnswer={showAnswer} marked={isMarked} id={match.params.examId} handleReset={() => handleReset(examContent.id)} disabled={isLocked} isMarkForReviewBreak={isMarkForReviewBreak} backFormreview={backFormreview} initiateBreak={initiateBreak} break_time={examContent.break_time} cancelBreak={cancelBreak} showSpecialModal={showSpecialModal} />

                            {showAnswer && (
                                <div className="transition-1 mt-5 mb-5">
                                    <ShowAnswer item={currentItem} showAnswer={showAnswer} />
                                </div>
                            )}



                        </div>



                    ) : ''}



                    {examtype && examtype == 'MAIN' && !timeout &&
                        <Prompt
                            message={(location, action) => {
                                if (action === 'POP') {

                                }

                                return location.pathname.includes("exam-form")
                                    ? true
                                    : `Are you sure you want to end the exam?`
                            }}
                        />
                        }

                    {examtype && examtype == 'MAIN' && !isMarkForReviewBreak &&
                        <ExamBreakModal
                            show={examContent && examContent.total_answered !== 0 ? modalState : false}
                            onHide={() => {
                                setModalState(false);
                            }}
                            break_time={examContent && examContent.break_time}
                            backdrop={'static'}
                            cancelBreak={cancelBreak}
                            initiateBreak={initiateBreak}
                            reviewBreak={reviewBreak}
                        />
                    }



                </Col>
            </Row>
        </Fragment>
    )
}

export default ExamForm
