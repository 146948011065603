import React from 'react';
import {useDroppable} from '@dnd-kit/core';

export function Droppable(props) {
  const {isOver, setNodeRef} = useDroppable({
    id: props.id,
  });
  const style = {
    color: isOver ? 'green' : undefined,
    border: isOver ? '1px solid green' : '1px solid gray',
    textAlign: 'left',
    paddingLeft: '45px',
    paddingRight:'45px',
  };
  
  
  return (
    <button ref={setNodeRef} style={style} className="droppable-item" id={`droppable-${props.id}`}>
      {props.children}
    </button>
  );
}