import React,{useEffect, useState} from 'react'
import { Modal, Button, Row, Col, Container } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';

import ReactMarkdown from 'react-markdown'

import './QuestionDetailsModal.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCheckCircle,faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const QuestionDetailsModal = (props) => {

    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [correctAnswer, setCorrectAnswer] = useState(null);

    const choices = props.content && props.content.options.choices && props.content.options.choices.map((item, idx) => (
        <p key={idx} className={`${props.content.correct_answer.includes(item.k) ? 'text-success' : props.content.user_answer.includes(item.k) && props.content.correct_answer !== props.content.user_answer ? 'text-danger' : ''}`}>{item.k}. {item.t}</p>
    ))

    const answerIsCorrect = (idx,key,value) => {
        const obj = props.content.correct_answer[idx];
        return obj[key] === value;
    }

    const getOptionDetails = (key,array) => {
        let result = array.filter(obj => {
            return obj.k === key
        })
        return result && result[0] && result[0].t || 'N/A';
    }


    useEffect(() => {
        if(props.content){
            if(props.content.options.direction === 'left'){
                setAnswers(props.content.options.right);
                setQuestions(props.content.options.left);
            }else if(props.content.options.direction === 'right'){
                setAnswers(props.content.options.left)
                setQuestions(props.content.options.right)
            }
            setCorrectAnswer(props.content.correct_answer);
            
        }
    }, [props.content])

    

    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                dialogClassName="modal-90w modal-dialog-custom"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Exam: {props.exam && props.exam}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {props.content && props.content.type && props.content.type.value !== 'DRAG_DROP' && (
                        <>
                            
                            
                            <p><b>Question {props.content && props.content.display} : </b>
                                <ReactMarkdown children={props.content && props.content.title} allowDangerousHtml />
                            </p>
                            {props.content && props.content.image && (
                                <img src={props.content.image} alt="" width="500px" />
                            )}
                            
                            {props.content && props.content.is_correct ? (
                                <p className="text-success">
                                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                                    Question Answered Correctly
                                </p>
                            ) :
                                <p className="text-danger">
                                    <FontAwesomeIcon icon={faTimesCircle} className="mr-2"/>
                                    Question Answered Incorrectly
                                </p>
                            }

                            <div>
                                <p><b>Given choices:</b></p>
                                {choices}

                                
                                {props.content.hint && (<p><b>Hint : </b> {<ReactMarkdown children={props.content.hint} allowDangerousHtml />} </p>)}
                                
                                <p><b>Your answer:</b> {props.content && props.content.user_answer && props.content.user_answer || 'No Answer Provided'}</p>
                                
                                <p className="text-success"><b>Correct answer:</b> {props.content && props.content.correct_answer}</p>
                                
                                {props.content && props.content.explanation && (<p><b>Explanation: </b>{<ReactMarkdown children={props.content.explanation} allowDangerousHtml />}</p>)}
                                
                                {props.content && props.content.reference && (<p><b>Reference: </b>{props.content && <ReactMarkdown children={props.content.reference} allowDangerousHtml /> }</p>)}
                                
                                {props.content && props.content.explanation_image && (
                                    <img src={props.content.explanation_image} alt="" width="500px" />
                                )}
                            </div>
                        </>
                    )}

                    {props.content && props.content.type && props.content.type.value === 'DRAG_DROP' && (
                        <>
                            <p><b>Question {props.content && props.content.display} : </b> <ReactMarkdown children={props.content && props.content.title} allowDangerousHtml /></p>

                            {props.content && props.content.is_correct ? (
                                <p className="text-success">
                                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                                    Question Answered Correctly
                                </p>
                            ) :
                                <p className="text-danger">
                                    <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                                    Question Answered Incorrectly
                                </p>
                            }

                            
                            {props.content.hint && (<p><b>Hint : </b> {props.content.hint && <ReactMarkdown children={props.content.hint} allowDangerousHtml />} </p>)}


                            <p><b>Your answer:</b></p>
                            <div className="container mb-5">

                                {props.content && props.content.options && props.content.user_answer && props.content.user_answer.length !== 0 && props.content.user_answer.map((obj,idx) => {
                                    const key = Object.keys(obj)[0];
                                    let is_answer_correct = answerIsCorrect(idx,key, obj[key]);
                                    
                                    return (
                                        <div className="d-flex" key={idx}> 

                                            
                                            {props.content.options.direction === 'left' ? (
                                                <>
                                                    <div className="bg-gray m-2 w-50">
                                                        <p className="m-0 p-3">
                                                            {/* {getOptionDetails(obj[key], "right")} */}
                                                            {getOptionDetails(key, questions)}
                                                        </p>
                                                    </div>

                                                    <div className={`${is_answer_correct ? "bg-light-green" : "details-modal-question-bg"} w-50  m-2`}>
                                                        <p className="m-0 p-3">
                                                            {/* {getOptionDetails(key, "left")} */}
                                                            {getOptionDetails(obj[key], answers)}
                                                        </p>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className={`${is_answer_correct ? "bg-light-green" : "details-modal-question-bg"} w-50  m-2`}>
                                                        <p className="m-0 p-3">
                                                            {/* {getOptionDetails(obj[key], answers)} */}
                                                            {getOptionDetails(obj[key], answers)}
                                                        </p>
                                                    </div>

                                                    <div className="bg-gray m-2 w-50">
                                                        <p className="m-0 p-3">
                                                            {/* {getOptionDetails(obj[key], "right")} */}
                                                            {getOptionDetails(key, questions)}
                                                        </p>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )
                                })}

                            </div>

                            <p className="text-success"><b>Correct answer:</b></p>
                            <div className="container mb-5">
                                
                                {props.content && props.content.options && props.content.correct_answer && props.content.correct_answer.length !== 0 &&  props.content.correct_answer.map((obj,idx) => {
                                    const key = Object.keys(obj)[0];
                                    return (
                                        <div className="d-flex answer_dragable_overlap_parent" key={idx}>

                                            {props.content.options.direction === 'left' ? (
                                                <>
                                                    <div className=" bg-gray border-bottom w-50 p-3 question_fixed">
                                                <p className="m-0">
                                                    {/* {getOptionDetails(key, "left")} */}
                                                    {getOptionDetails(key, questions)}
                                                </p>
                                            </div>
                                            <div className="bg-light-green  border-bottom w-50 p-3 answer_dragable_overlap" >
                                                <p className="m-0">
                                                   {/* {getOptionDetails(obj[key],"right")} */}
                                                   {getOptionDetails(obj[key], answers)}
                                                    
                                                </p>
                                            </div>
                                                </>
                                            ) : 
                                            <>
                                                <div className="bg-light-green  border-bottom w-50 p-3  answer_dragable_overlap" >
                                                    <p className="m-0">
                                                        {/* {getOptionDetails(obj[key],"right")} */}
                                                        {getOptionDetails(obj[key], answers)}
                                                        
                                                    </p>
                                                </div>
                                                
                                                
                                                <div className=" bg-gray border-bottom w-50 p-3 question_fixed">
                                                    <p className="m-0">
                                                        {/* {getOptionDetails(key, "left")} */}
                                                        {getOptionDetails(key, questions)}
                                                    </p>
                                                </div>
                                                
                                            </>
                                            }
                                            
                                            
                                        
                                        </div>
                                    )
                                })}
                                
                            </div>


                            {props.content && props.content.explanation && (<p><b>Explanation: </b>{<ReactMarkdown children={props.content.explanation} allowDangerousHtml />}</p>)}
                            {props.content && props.content.reference && (<p><b>Reference: </b>{<ReactMarkdown children={props.content.reference} allowDangerousHtml /> }</p>)}
                            {props.content && props.content.explanation_image && (
                                <img src={props.content.explanation_image} alt="" width="500px" />
                            )}

                        </>


                    )}
                    <Pagination className="mt-3">

                        {props.content && props.content.display !== props.firstItemDisplay && (
                            <Button className="mr-1 rounded-0 c-btn-info" onClick={() => props.nextPrevPage(props.content.display - 1)}>Prev</Button>
                        )}

                        {props.content && props.content.display !== props.lastItemDisplay && (
                            <Button className="ml-1 rounded-0 c-btn-info" onClick={() => props.nextPrevPage(props.content.display + 1)}>Next</Button>
                        )}
                    </Pagination>

                </Modal.Body>
            </Modal>

        </div>
    )
}

export default QuestionDetailsModal
