import {

    MARK_FOR_REVIEW_REQUEST,
    MARK_FOR_REVIEW_SUCCESS,
    MARK_FOR_REVIEW_FAIL,

    GET_EXAM_REQUEST,
    GET_EXAM_SUCCESS,
    GET_EXAM_FAIL,
    GET_EXAM_LIST_REQUEST,
    GET_EXAM_LIST_SUCCESS,
    GET_EXAM_LIST_FAIL,

    GET_USER_EXAMS_REQUEST,
    GET_USER_EXAMS_SUCCESS,
    GET_USER_EXAMS_FAIL,

    ANSWER_SUBMIT_REQUEST,
    ANSWER_SUBMIT_SUCESS,
    ANSWER_SUBMIT_FAIL,

    USER_EXAM_DETAILS_REQUEST,
    USER_EXAM_DETAILS_SUCCESS,
    USER_EXAM_DETAILS_FAIL,
    PRACTICE_EXAM_RESET,
    PRACTICE_EXAM_RESET_SUCCESS,
    PRACTICE_EXAM_RESET_FAIL,

    EXAM_BREAK_REQUEST,
    EXAM_BREAK_FAIL,
    EXAM_BREAK_SUCCESS,

    EXAM_CONTINUE_REQUEST,
    EXAM_CONTINUE_SUCCESS,
    EXAM_CONTINUE_FAIL,

    EXAM_MARK_FOR_REVIEW_REQUEST,
    EXAM_MARK_FOR_REVIEW_SUCCESS,
    EXAM_MARK_FOR_REVIEW_FAIL,

    EXAM_END_REQUEST,
    EXAM_END_SUCCESS,
    EXAM_END_FAIL
} from '../constants/examConstants'
import { returnErrors, createMessage } from './messageActions';

import { BASEURL } from '../utils/config';

import { _axios } from './axios/axios_conf'

export const getExamContent = (id, type) => (dispatch, getState) => {
    dispatch({ type: GET_EXAM_REQUEST })

    if (type == "PRACTICE") {

        _axios.post(`/practice-exams/`, { sub_category: id })

            .then(res => {
                dispatch({
                    type: GET_EXAM_SUCCESS,
                    payload: res.data.data
                })
            })
            .catch(err => {
                dispatch({ type: GET_EXAM_FAIL })
                dispatch(returnErrors(err.response.data.errors, err.response.status))
            })

    } else {
        _axios.post(`/user-exams/`, { exam: id })

            .then(res => {
                dispatch({
                    type: GET_EXAM_SUCCESS,
                    payload: res.data.data
                })
            })
            .catch(err => {

                dispatch({ type: GET_EXAM_FAIL })
                dispatch(returnErrors(err.response.data.errors, err.response.status))
            })
    }
}

export const practiceExamReset = (id) => (dispatch, getState) => {

    dispatch({ type: PRACTICE_EXAM_RESET })

    _axios.put(`/practice-exams/${id}/`, { reset: 'True' })

        .then(res => {

            dispatch({
                type: PRACTICE_EXAM_RESET_SUCCESS,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch({ type: PRACTICE_EXAM_RESET_FAIL })
            dispatch(returnErrors(err.response.data.errors, err.response.status))
        })
}

export const getExamList = (courseId) => (dispatch, getState) => {
    dispatch({ type: GET_EXAM_LIST_REQUEST })

    _axios.get(`/exams/${courseId}`)
        .then(res => {
            dispatch({
                type: GET_EXAM_LIST_SUCCESS,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch({ type: GET_EXAM_LIST_FAIL });
            dispatch(returnErrors(err.response.data.errors, err.response.status));
        })
}


export const removeExamContent = () => (dispatch, getState) => {
    dispatch({ type: GET_EXAM_FAIL });
}

export const getUserExams = () => (dispatch, getState) => {
    dispatch({ type: GET_USER_EXAMS_REQUEST });

    _axios.get(`/user-exams/`)
        .then(res => {
            dispatch({
                type: GET_USER_EXAMS_SUCCESS,
                payload: res.data.data
            })
        })
        .catch(err => {

            dispatch({ type: GET_USER_EXAMS_FAIL });
            dispatch(
                returnErrors(
                    err.response ? err.response.data.errors : err,
                    err.response ? err.response.status : err
                )
            );
        })
}

export const inProgressExam = (examId) => (dispatch, getState) => {
    dispatch({ type: GET_EXAM_REQUEST })
    _axios.get(`/user-exams/${examId}`,)
        .then(res => {
            dispatch({
                type: GET_EXAM_SUCCESS,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch({ type: GET_EXAM_FAIL })
            dispatch(returnErrors(err.response.data.errors, err.response.status))
        })
}


export const questionAnswerSubmitOnlyMarkForReview = (type, qid, answers, isMarkForReviewBreak) => async (dispatch) => {
    const mark_for_review = isMarkForReviewBreak ? 'True' : 'False'

    dispatch({ type: ANSWER_SUBMIT_REQUEST })

    return _axios.put(`/user-exam-questions/${qid}/?mark_for_review=${mark_for_review}`,
        {
            'user_answer': type === 'DRAG_DROP' && JSON.stringify(answers) || answers.toString(),
            'mark_for_review': mark_for_review
        })
        .then(res => {

            dispatch({
                type: ANSWER_SUBMIT_SUCESS,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch({ type: ANSWER_SUBMIT_FAIL })
            dispatch(returnErrors(err.response.data.errors, err.response.status))
            throw err;
        })
}


export const questionAnswerSubmit = (item, answers, type, isMarked) => async (dispatch) => {

    let qid = item.id;
    dispatch({ type: ANSWER_SUBMIT_REQUEST })

    let body;
    if (answers && answers.length) {
        body = {
            'user_answer': item.type.value === 'DRAG_DROP' && JSON.stringify(answers) || answers.toString(),
            'mark_for_review': isMarked ? 'True' : 'False'
        }
    } else {
        body = {
            'mark_for_review': isMarked ? 'True' : 'False'
        }
    }


    if (type == 'PRACTICE') {
        return _axios.put(`/practice-exam-questions/${qid}/`, body)
            .then(res => {
                // dispatch(setQuestionMarkForReview(qid,type,isMarked))
                dispatch({
                    type: ANSWER_SUBMIT_SUCESS,
                    payload: res.data.data
                })
                // dispatch(createMessage({ answerSubmitted: 'Answer Submitted' }));
            })
            .catch(err => {
                dispatch({ type: ANSWER_SUBMIT_FAIL })
                dispatch(returnErrors(err.response.data.errors, err.response.status))
                throw err;
            })
    } else {
        return _axios.put(`/user-exam-questions/${qid}/`, body)
            .then(res => {
                // dispatch(setQuestionMarkForReview(qid,type,isMarked))
                dispatch({
                    type: ANSWER_SUBMIT_SUCESS,
                    payload: res.data.data
                })
                // dispatch(createMessage({ answerSubmitted: 'Answer Submitted' }));
            })
            .catch(err => {
                dispatch({ type: ANSWER_SUBMIT_FAIL })
                dispatch(returnErrors(err.response.data.errors, err.response.status));
                throw err;
            })
    }
}

export const userExamDetails = (examId) => (dispatch, getState) => {
    dispatch({ type: USER_EXAM_DETAILS_REQUEST })

    _axios.get(`/user-exam-result/${examId}/`)
        .then(res => {
            dispatch({
                type: USER_EXAM_DETAILS_SUCCESS,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch({ type: USER_EXAM_DETAILS_FAIL })
            dispatch(returnErrors(err.response.data.errors, err.response.status))
        })
}

export const setQuestionMarkForReview = (qid, type, bool) => async (dispatch) => {



    const marked = bool ? 'True' : 'False'

    dispatch({ type: MARK_FOR_REVIEW_REQUEST })

    if (type == 'PRACTICE') {
        return _axios.put(`/practice-exam-questions/${qid}/`, { 'mark_for_review': marked })
            .then(res => {

                dispatch({
                    type: MARK_FOR_REVIEW_SUCCESS,
                    payload: res.data.data
                })
                // dispatch(createMessage({ markForReview: 'Question Added To Mark For Review' }));
            })
            .catch(err => {
                dispatch({ type: MARK_FOR_REVIEW_FAIL })
                dispatch(returnErrors(err.response.data.errors, err.response.status))
                throw err;
            })
    } else {
        return _axios.put(`/user-exam-questions/${qid}/`, { 'mark_for_review': marked })
            .then(res => {

                dispatch({
                    type: MARK_FOR_REVIEW_SUCCESS,
                    payload: res.data.data
                })
                // dispatch(createMessage({ markForReview: 'Question Added To Mark For Review' }));
            })
            .catch(err => {
                dispatch({ type: MARK_FOR_REVIEW_FAIL })
                dispatch(returnErrors(err.response.data.errors, err.response.status));
                throw err;
            })
    }

}

export const breakExam = (examId) => async (dispatch) => {

    dispatch({ type: EXAM_BREAK_REQUEST })


    return _axios.put(`/user-exams/${examId}/`, { 'is_pause': 'True' })
        .then(res => {
            dispatch({
                type: EXAM_BREAK_SUCCESS,
            })
            return res;
        })
        .catch(err => {

            dispatch({ type: EXAM_BREAK_FAIL })
            dispatch(returnErrors(err.response.data.errors, err.response.status))
            throw err;
        })
}

export const continueExam = (examId) => (dispatch) => {

    dispatch({ type: EXAM_CONTINUE_REQUEST })


    _axios.put(`/user-exams/${examId}/`, { 'is_continue': 'True' })
        .then(res => {
            dispatch({
                type: EXAM_CONTINUE_SUCCESS,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch({ type: EXAM_CONTINUE_FAIL })
            dispatch(returnErrors(err.response.data.errors, err.response.status))
        })
}

export const markForReviewBreak = (examId) => async (dispatch) => {
    dispatch({ type: EXAM_MARK_FOR_REVIEW_REQUEST });

    return _axios.get(`/user-exams/${examId}/?mark_for_review=True`)
        .then(res => {
            dispatch({
                type: EXAM_MARK_FOR_REVIEW_SUCCESS,
                payload: res.data.data
            })
        })
        .catch(err => {
            dispatch({ type: EXAM_MARK_FOR_REVIEW_FAIL })
            dispatch(returnErrors(err.response.data.errors, err.response.status))
            throw err;
        })
}


export const endExam = (examId, history) => (dispatch) => {
    dispatch({ type: EXAM_END_REQUEST })

    _axios.put(`/user-exams/${examId}/`, { 'is_end': 'True' })
        .then(res => {
            dispatch({
                type: EXAM_END_SUCCESS,
            })
            dispatch(createMessage({ examEnd: 'Exam Ended Successfully' }));
            history.push(`/exam/details/${examId}`);
        })
        .catch(err => {
            dispatch({ type: EXAM_END_FAIL })
            dispatch(returnErrors(err.response.data.errors, err.response.status))
        })
}