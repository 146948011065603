import React from 'react';
import {useDraggable} from '@dnd-kit/core';

export function Draggable(props) {
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: props.id,
    disabled: props.disabled,
  });
  const style = {
    ...(transform && {transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`}),
    ...(props.order && {order:`${parseInt(props.order)}`}),
    touchAction: 'none',
    textAlign: 'left',
    paddingLeft: '45px',
    paddingRight:'45px',
  };
  
  
  return (
    <button ref={setNodeRef} style={style} {...listeners} {...attributes} className={props.className} id={`${props.id}`}>
      {props.children}
    </button>
  );
}
