import { USER_LOGIN_REQEST, USER_LOGIN_SUCEESS, USER_LOGOUT, USER_LOGIN_FAIL, SET_CURRENT_USER, USER_LOGOUT_FAIL, USER_DETAILS_REQUEST, USER_DETAILS_SUCCESS, USER_DETAILS_FAIL, PASSWORD_CHANGE_REQUEST, PASWORD_CHANGE_SUCCESS, PASSWORD_CHANGE_FAIL, DESTROY_SESSION } from '../constants/userConstants'
import { createMessage, returnErrors } from './messageActions';
import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import { BASEURL } from '../utils/config';
import jwt_decode from 'jwt-decode';


import { _axios } from './axios/axios_conf'
import { getRefreshTokenTimerRef } from '../config/app';

export const login = (username, password) => (dispatch, getState) => {
    dispatch({ type: USER_LOGIN_REQEST })

    _axios.post(`/login/`, { username, password })
        .then(res => {
            const { access, refresh, courses } = res.data;

            localStorage.setItem('jwtToken', access);
            localStorage.setItem('refreshToken', refresh);
            setAuthToken(access);
            const userInfo = { ...jwt_decode(access), refreshToken: refresh };

            dispatch({
                type: USER_LOGIN_SUCEESS,
                payload: { userInfo, userCourses: courses }
            })
            dispatch(createMessage({ login: 'Successfully login' }));
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            localStorage.setItem('userCourses', JSON.stringify(courses));
        })
        .catch(err => {
            dispatch({ type: USER_LOGIN_FAIL })
            dispatch(createMessage({ login: 'Incorrect username or password' }));
            // dispatch(
            //     returnErrors(
            //         err.response ? err.response.data.errors : err,
            //         err.response ? err.response.status : err
            //     )
            // );
        })
}

//Set Logged in user
export const setCurrentUser = (decoded) => {

    return {
        type: SET_CURRENT_USER,
        payload: decoded
    }
}

export const logout = () => (dispatch) => {
    const refresh_token = localStorage.refreshToken;
    _axios.post(`/logout/`, { refresh_token })
        .then(res => {
            const refreshTokenTimerRef = getRefreshTokenTimerRef();
            refreshTokenTimerRef && clearTimeout(refreshTokenTimerRef);
            setAuthToken(false);
            dispatch({
                type: USER_LOGOUT,
            })
            dispatch({ type: DESTROY_SESSION });
            dispatch(createMessage({ logout: 'Successfully Logout' }));
            localStorage.removeItem('userInfo');
            localStorage.removeItem('userCourses');
            localStorage.removeItem('jwtToken');
            localStorage.removeItem('refreshToken');
        })
        .catch(err => {
            setAuthToken(false);
            // dispatch(createMessage({ logout: 'Successfully Logout' }));
            dispatch({ type: USER_LOGOUT_FAIL });
            dispatch({ type: DESTROY_SESSION });
            localStorage.removeItem('userInfo');
            localStorage.removeItem('userCourses');
            localStorage.removeItem('jwtToken');
            localStorage.removeItem('refreshToken');
        })


}

// export const loginRefresh = (refresh) => async (dispatch) => {
//      return axios.post(`/login/refresh/`, { refresh })
//     .then(res => {
//         const { access } = res.data;
//         localStorage.setItem('jwtToken', access);
//         setAuthToken(access);
//         // const userInfo = jwt_decode(access);
//         // dispatch({type: SET_CURRENT_USER,payload: userInfo})
//         // dispatch(logout());
//         return res;
//     })
//     .catch(err => {
//         dispatch(removeLocalStorage())
//         // 
//         throw err;
//     })
// }

export const getUserDetails = () => (dispatch) => {
    dispatch({ type: USER_DETAILS_REQUEST })

    _axios.get(`/user/`)
        .then(res => {

            dispatch({
                type: USER_DETAILS_SUCCESS,
                payload: res.data.data
            })

        })
        .catch(err => {
            dispatch({ type: USER_DETAILS_FAIL })
        })
}


export const userPasswordChange = (old_password, new_password) => (dispatch, getState) => {
    dispatch({ type: PASSWORD_CHANGE_REQUEST })

    _axios.put(`/user/`, { old_password, new_password })
        .then(res => {

            dispatch({
                type: PASWORD_CHANGE_SUCCESS,
            })
            dispatch(createMessage({ password_change: 'Password Successfully Changed' }));

        })
        .catch(err => {
            dispatch({ type: PASSWORD_CHANGE_FAIL })
            dispatch(returnErrors(err.response.data.errors, err.response.status))
        })
}


export const removeLocalStorage = () => (dispatch, getState) => {



    localStorage.removeItem('userInfo');
    localStorage.removeItem('userCourses');
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('refreshToken');

    setAuthToken(false);
    dispatch({ type: USER_LOGOUT })
    // dispatch(createMessage({ logout: 'Successfully Logout' }));
}