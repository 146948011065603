import React,{useState} from 'react';
import moment from 'moment';
import { DatePicker  } from 'antd';
import {Button} from 'react-bootstrap';
moment.locale('en')

const DateFilter = ({handleDateFilter}) => {
    const [dateRange, setDateRange] = useState([]);

    const { RangePicker } = DatePicker;
    
    const onChange = (value) =>  {
        const fromDate = value[0].format('MM-DD-YYYY');
        const toDate = value[1].format('MM-DD-YYYY');
        const range = [fromDate,toDate]
        setDateRange(range)
    };

    const onFilter = (e) => {
        e.preventDefault();
        handleDateFilter(dateRange);
    }

    
    
    return (
        <div className="mt-3">
            <form className="d-flex align-items-center flex-wrap  content-wrap-pad">
                <RangePicker onChange={onChange} size="large" className="mr-2"/>
                <Button type="submit" onClick={onFilter} className="custom-filter-btn mb-mrg-top-2 c-btn-info rounded-0">Filter</Button>
            </form>
        </div>
    )
}

export default DateFilter
