export const GET_EXAM_REQUEST  = 'GET_EXAM_REQUEST';
export const GET_EXAM_SUCCESS  = 'GET_EXAM_SUCCESS';
export const GET_EXAM_FAIL  = 'GET_EXAM_FAIL';
export const GET_EXAM_LIST_REQUEST  = 'GET_EXAM_LIST_REQUEST';
export const GET_EXAM_LIST_SUCCESS  = 'GET_EXAM_LIST_SUCCESS';
export const GET_EXAM_LIST_FAIL  = 'GET_EXAM_LIST_FAIL';


export const GET_USER_EXAMS_REQUEST = 'GET_USER_EXAMS_REQUEST'
export const GET_USER_EXAMS_SUCCESS = 'GET_USER_EXAMS_SUCCESS'
export const GET_USER_EXAMS_FAIL = 'GET_USER_EXAMS_SUCCESS'


export const ANSWER_SUBMIT_REQUEST = 'ANSWER_SUBMIT_REQUEST'
export const ANSWER_SUBMIT_SUCESS = 'ANSWER_SUBMIT_SUCESS'
export const ANSWER_SUBMIT_FAIL = 'ANSWER_SUBMIT_FAIL'


export const USER_EXAM_DETAILS_REQUEST = 'USER_EXAM_DETAILS_REQUEST'
export const USER_EXAM_DETAILS_SUCCESS = 'USER_EXAM_DETAILS_SUCCESS'
export const USER_EXAM_DETAILS_FAIL = 'USER_EXAM_DETAILS_FAIL'


export const EXAM_END_REQUEST = "EXAM_END_REQUEST";
export const EXAM_END_SUCCESS = "EXAM_END_SUCCESS";
export const EXAM_END_FAIL = "EXAM_END_FAIL";


export const MARK_FOR_REVIEW_REQUEST = "MARK_FOR_REVIEW_REQUEST";
export const MARK_FOR_REVIEW_SUCCESS  = 'MARK_FOR_REVIEW_SUCCESS';
export const MARK_FOR_REVIEW_FAIL = "MARK_FOR_REVIEW_FAIL";

export const PRACTICE_EXAM_RESET = 'PRACTICE_EXAM_RESET';
export const PRACTICE_EXAM_RESET_SUCCESS = 'PRACTICE_EXAM_RESET_SUCCESS';
export const PRACTICE_EXAM_RESET_FAIL = 'PRACTICE_EXAM_RESET_FAIL';

export const EXAM_BREAK_REQUEST = 'EXAM_BREAK_REQUEST';
export const EXAM_BREAK_FAIL = 'EXAM_BREAK_FAIL';
export const EXAM_BREAK_SUCCESS = 'EXAM_BREAK_SUCCESS';

export const EXAM_CONTINUE_REQUEST = 'EXAM_CONTINUE_REQUEST';
export const EXAM_CONTINUE_SUCCESS = 'EXAM_CONTINUE_SUCCESS';
export const EXAM_CONTINUE_FAIL = 'EXAM_CONTINUE_FAIL';


export const EXAM_MARK_FOR_REVIEW_REQUEST = 'EXAM_MARK_FOR_REVIEW_REQUEST';
export const EXAM_MARK_FOR_REVIEW_SUCCESS = 'EXAM_MARK_FOR_REVIEW_SUCCESS';
export const EXAM_MARK_FOR_REVIEW_FAIL = 'EXAM_MARK_FOR_REVIEW_FAIL';