import moment from 'moment'

export const tableColumns = (handleEdit) => {
  return [
    {
      text: '#SL',
      dataField: '',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
    },
    {
      text: 'Exam Date',
      dataField: 'created_at',
      formatter: (cell,row,rowIndex) => {
        return `${moment(cell).format("DD-MM-YYYY")? moment(cell).format("DD-MM-YYYY"):moment(cell).format("DD-MM-YYYY") }`;
      },
    },
    {
      text: 'Course',
      dataField: 'course'
    },
    {
      text: 'Exam Name',
      dataField: 'exam'
    },
    /* {
      text: 'Total Question',
      dataField: 'total_question'
    }, */
    {
      text: 'Corrent Answer',
      dataField: 'correct_answer'
    },
    {
      text: 'Result',
      dataField: 'result'
    },
    {
      text: 'Action',
      dataField: 'id',
      formatter: (cell, row, rowIndex) => {
        return <button className='btn c-btn-info rounded-0' onClick={handleEdit(row)}>Details</button>
      },
    },
  ];
};