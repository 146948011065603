import {

    MARK_FOR_REVIEW_REQUEST,
    MARK_FOR_REVIEW_SUCCESS,
    MARK_FOR_REVIEW_FAIL,

    GET_EXAM_REQUEST,
    GET_EXAM_SUCCESS,
    GET_EXAM_FAIL,
    GET_EXAM_LIST_REQUEST,
    GET_EXAM_LIST_SUCCESS,
    GET_EXAM_LIST_FAIL,

    GET_USER_EXAMS_REQUEST,
    GET_USER_EXAMS_SUCCESS,
    GET_USER_EXAMS_FAIL,

    ANSWER_SUBMIT_REQUEST,
    ANSWER_SUBMIT_SUCESS,
    ANSWER_SUBMIT_FAIL,

    USER_EXAM_DETAILS_REQUEST,
    USER_EXAM_DETAILS_SUCCESS,
    USER_EXAM_DETAILS_FAIL,

    PRACTICE_EXAM_RESET,
    PRACTICE_EXAM_RESET_SUCCESS,
    PRACTICE_EXAM_RESET_FAIL,

    EXAM_BREAK_REQUEST,
    EXAM_BREAK_FAIL,
    EXAM_BREAK_SUCCESS,

    EXAM_CONTINUE_REQUEST,
    EXAM_CONTINUE_SUCCESS,
    EXAM_CONTINUE_FAIL,

    EXAM_MARK_FOR_REVIEW_REQUEST,
    EXAM_MARK_FOR_REVIEW_SUCCESS,
    EXAM_MARK_FOR_REVIEW_FAIL,

    EXAM_END_REQUEST,
    EXAM_END_SUCCESS,
    EXAM_END_FAIL
} from '../constants/examConstants';


const initialState = {
    examContent: null,
    examList: null,
    userExamList: null,
    loading: false,
    examDetails: null,
}

export const examReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EXAM_REQUEST:
        case GET_USER_EXAMS_REQUEST:
        case GET_EXAM_LIST_REQUEST:
        case ANSWER_SUBMIT_REQUEST:
        case USER_EXAM_DETAILS_REQUEST:
        case EXAM_END_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_EXAM_SUCCESS:
            return {
                ...state,
                examContent: action.payload,
                loading: false,
            }
        case GET_EXAM_FAIL:
            return {
                ...state,
                loading: false,
                examContent: null
            }

        case GET_EXAM_LIST_SUCCESS:
            return {
                ...state,
                examList: action.payload,
                loading: false,
            }

        case GET_EXAM_LIST_FAIL:
            return {
                ...state,
                loading: false,
                examList: null
            }

        case GET_USER_EXAMS_SUCCESS:
            return {
                ...state,
                userExamList: action.payload,
                loading: false,
            }
        case GET_USER_EXAMS_FAIL:
            return {
                ...state,
                loading: false,
                examList: null
            }

        case ANSWER_SUBMIT_SUCESS:
            return {
                ...state,
                examContent: action.payload,
                loading: false,
            }
        case ANSWER_SUBMIT_FAIL:
            return {
                ...state,
                loading: false,
            }
        case USER_EXAM_DETAILS_SUCCESS:
            return {
                ...state,
                examDetails: action.payload,
                loading: false,
            }
        case USER_EXAM_DETAILS_FAIL:
            return {
                ...state,
                examDetails: null,
                loading: false
            }
        case MARK_FOR_REVIEW_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case MARK_FOR_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                examContent: action.payload,
            }
        case MARK_FOR_REVIEW_FAIL:
            return {
                ...state,
                loading: false,
            }
        case PRACTICE_EXAM_RESET:
            return {
                ...state,
                loading: true,
            }
        case PRACTICE_EXAM_RESET_SUCCESS:
            return {
                ...state,
                loading: false,
                examContent: action.payload,
            }

        case PRACTICE_EXAM_RESET_FAIL:
            return {
                ...state,
                loading: false,
            }
        case EXAM_BREAK_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case EXAM_BREAK_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case EXAM_BREAK_FAIL:
            return {
                ...state,
                loading: false,
            }
        case EXAM_CONTINUE_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case EXAM_CONTINUE_SUCCESS:
            return {
                ...state,
                loading: false,
                examContent: action.payload,
            }
        case EXAM_CONTINUE_FAIL:
            return {
                ...state,
                loading: false,
            }


        case EXAM_MARK_FOR_REVIEW_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case EXAM_MARK_FOR_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                examContent: action.payload,
            }
        case EXAM_MARK_FOR_REVIEW_FAIL:
            return {
                ...state,
                loading: false,
            }

        case EXAM_END_SUCCESS:
            return {
                ...state,
                loading: false,
                examContent: null,
            }
        case EXAM_END_FAIL:
            return {
                ...state,
                loading: false,
                examContent: null,
            }
        default:
            return state
    }
}