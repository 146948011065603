import {
    GET_CONTENT_REQUEST,
    GET_CONTENT_SUCCESS,
    GET_CONTENT_FAIL,
    GET_COURSE_CONFIG_REQUEST,
    GET_COURSE_CONFIG_FAIL,
    GET_COURSE_CONFIG_SUCCESS
} from '../constants/courseConstansts';


const initialState = {
    courseContent: {},
    loading: false,
    courseConfig: null,
}

export const courseReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTENT_REQUEST:
        case GET_COURSE_CONFIG_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_CONTENT_SUCCESS:
            console.log("PAYLOAD >> ", action.payload)
            return {
                ...state,
                courseContent: action.payload,
                loading: false,
            }
        case GET_CONTENT_FAIL:
            return {
                ...state,
                loading: false,
                courseContent: null
            }
        case GET_COURSE_CONFIG_SUCCESS:
            return {
                ...state,
                courseConfig: action.payload,
                loading: false,
            }
        case GET_COURSE_CONFIG_FAIL:
            return {
                ...state,
                courseConfig: null,
                loading: false,
            }

        default:
            return state
    }
}