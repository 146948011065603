import React from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";



import "./ReactBootstrapTable.css"





const ReactBootstrapTable = ({ data, cols }) => {
    return (
        <div className="mt-3">
            <BootstrapTable
                keyField="id"
                data={data}
                columns={cols}
                wrapperClasses="table-responsive content-wrap-pad"
            />
        </div>
    )
}

export default ReactBootstrapTable
