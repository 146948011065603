import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import ContentHeader from "../../../../components/layout/content_header/ContentHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import "../change_password/ChangePassword.css";

import Loader from '../../../../components/helpers/Loader'
import { getUserDetails } from '../../../../actions/userActions';

const PersonalInfo = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserDetails())
    }, [])

    const user = useSelector(state => state.user)
    const { loading, userDetails } = user;

    return (
        <Fragment>
            {/* {loading && <Loader />} */}
            {userDetails &&

                <div className="content_bg py-3">
                    <div className="change_password_header d-flex justify-content-between align-items-center flex-wrap">
                        <ContentHeader title="Personal Information" sub_title="Update your personal informaiton" />
                        
                    </div>
                    <div className="change_password_form p-3 mt-5">
                        <Form >
                            <div className="basic_info mb-5">
                                <div className="basic_p_info pb-3">
                                    <h5>Basic Information</h5>
                                </div>
                                <Form.Group controlId='user_name'>
                                    <Form.Row>
                                        <Form.Label column lg={4} className="fs-6">
                                            User Name
                    </Form.Label>
                                        <Col lg={8}>
                                            <Form.Control className="login-input-form px-8 py-3 fs-6" type='text' value={userDetails.username} disabled></Form.Control>

                                        </Col>
                                    </Form.Row>


                                </Form.Group>
                                <Form.Group controlId='fast_name'>
                                    <Form.Row>
                                        <Form.Label column lg={4} className="fs-6">
                                            Fast Name
                    </Form.Label>
                                        <Col lg={8}>
                                            <Form.Control className="login-input-form px-8 py-3 fs-6" type='text' value={userDetails.first_name} disabled></Form.Control>

                                        </Col>
                                    </Form.Row>


                                </Form.Group>

                                <Form.Group controlId='last_name'>
                                    <Form.Row>
                                        <Form.Label column lg={4} className="fs-6">
                                            Last Name
                    </Form.Label>
                                        <Col lg={8}>
                                            <Form.Control className="login-input-form px-8 py-3 fs-6" type='text' value={userDetails.last_name} disabled></Form.Control>

                                        </Col>
                                    </Form.Row>
                                </Form.Group>

                            </div>
                            <div className="basic_info mb-5">
                                <div className="basic_p_info pb-3">
                                    <h5>Contact Information</h5>
                                </div>

                                <Form.Group controlId='contact_number'>
                                    <Form.Row>
                                        <Form.Label column lg={4} className="fs-6">
                                            Contact Number
                    </Form.Label>
                                        <Col lg={8}>
                                            <InputGroup>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text className="input_group_text_icon">
                                                        <FontAwesomeIcon icon={faPhoneAlt} className="fs-6" />
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Col lg={8}>
                                                    <Form.Control className="login-input-form px-8 py-3 fs-6" type='text' value={userDetails.contact_number} disabled></Form.Control>

                                                </Col>
                                            </InputGroup>
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                                <Form.Group controlId='email'>
                                    <Form.Row>
                                        <Form.Label column lg={4} className="fs-6">
                                            Email Address
                    </Form.Label>
                                        <Col lg={8}>
                                            <InputGroup>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text className="input_group_text_icon">@</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control className="login-input-form px-8 py-3 fs-6" type='text' value={userDetails.email} disabled></Form.Control>
                                            </InputGroup>
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                            </div>
                        </Form>
                    </div>

                </div>

            }

        </Fragment>
    )
}

export default PersonalInfo
