import React , { Fragment } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import "./BlankPage.css";

const BlankPage = () => {
    return (
        <Fragment>
            <div className="blank-page-area content_bg my-5">
                <div className="d-flex flex-column align-items-center py-10">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="no-data-icon-color" />
                    <h2 className="mt-1">No data available!</h2>
                </div>
            </div>
        </Fragment>
    )
}

export default BlankPage
