import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ContentHeader from "../../../components/layout/content_header/ContentHeader";
import CourseMetarial from "../../../components/layout/card/course/CourseMetarial";
import { faUniversity, faVideo, faTasks } from "@fortawesome/free-solid-svg-icons";
import "./CourseDetails.css";
import { Row, Col } from 'react-bootstrap';
import _ from 'underscore'

const CourseDetails = ({ match }) => {
    const [currentCourse, setCurrentCourse] = useState(null)

    const user = useSelector(state => state.user)
    const { userCourses } = user;

    const fn = (str) => {
        return (currentCourse.subscriptions || []).includes(str);
    }

    const courseMetarialConfig = [
        {
            icon: faUniversity,
            permissionKey: 'document',
            title: 'Classroom Meterial',
            value: "document",
            link: `/course/topic/${match.params.id}?type=document`
        },
        {
            icon: faVideo,
            permissionKey: 'video',
            title: 'Video Tutorial',
            value: "video",
            link: `/course/topic/${match.params.id}?type=video`
        },
        {
            icon: faTasks,
            permissionKey: 'exam',
            title: 'Exam Simulator',
            value: "exam",
            link: `/exam/select/${match.params.id}`
        }
    ]


    useEffect(() => {
        setCurrentCourse(userCourses.filter(x => x.id === match.params.id)[0])
    }, [currentCourse,match.params.id])


    return (

        <Fragment>
            <div className="content_bg">

                {currentCourse ? (
                    <div>
                        <ContentHeader title={currentCourse.title} sub_title="Here, you getting Classroom Material, Video Tutorial and Exam Simulator" />
                    <div className="courses content-wrap-pad pb-5">
    
                        <Row className="w-100">
                            {courseMetarialConfig.map((item, idx) => (
                                <Col md={4} key={idx}>
                                    <CourseMetarial icon={item.icon} value={item.value} permission={fn(item.permissionKey)} title={item.title} link={item.link} />
                                </Col>
    
                            ))}
                        </Row>
    
    
    
                    </div>
                    </div>
                ) : null}
            </div>
        </Fragment>


    )
}

export default CourseDetails
