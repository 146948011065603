import { 
    GET_FAQ_REQUEST,
    GET_FAQ_SUCCESS,
    GET_FAQ_FAIL,
} from '../constants/settingsConstants';


const initialState = {
    faq: null,
    loading: false,
}

export const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FAQ_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GET_FAQ_SUCCESS:
            return {
                ...state,
                faq: action.payload,
                loading: false,
            }
        case GET_FAQ_FAIL:
            return {
                ...state,
                loading: false,
                faq: null
            }
        default:
            return state
    }
}