export const tableData = () => {
    return [
      {
        title: 'Course Name',
        render: rowData => {
          return <span>{rowData.title}</span>;
        },
      },
      // {
      //   title: 'Subscription',
      //   render: rowData => {
      //     return <span>{rowData.subscription}</span>;
      //   },
      // },
      {
        title: 'Metarial Type',
        render: rowData => {
          return <span>{rowData.type}</span>;
        },
      },
      {
        title: 'From Date',
        render: rowData => {
          return <span>{rowData.start_date}</span>;
        },
      },
      {
        title: 'End Date',
        render: rowData => {
          return <span>{rowData.end_date}</span>;
        },
      },
      
    ];
};