import React from 'react';
import 'antd/dist/antd.css';
import './ImageViewer.css'
import { Image } from 'antd';

const ImageViewer = ({ img,alt }) => {
    return (
        <div>
            <Image
                src={img}
                alt={alt}
                placeholder={true}
            />
        </div>
    )
}

export default ImageViewer
