import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { numberToLetter } from '../../../../utils/func';
import { Form } from 'react-bootstrap';

const OptionChoice = ({ options, handleChoice, type, choice, disabled }) => {


    // const [singlechoice, setSingleChoice] = useState();
    // const [values, setValues] = useState([]);
    // const [multipleChoice, setMultipleChoice] = useState(new Map());

    // useEffect(() => {
    //     // todo     
    // }, [values])

    const handleUserChoice = (value) => {
        // const hasValue = values.includes(value);
        // let newValues;
        // if (hasValue) newValues = values.filter(i => i !== value);
        // else newValues = [...values, value];
        // setValues(newValues);

        handleChoice(value)


        // if (type === 'radio') {
        //     setSingleChoice(value)
        //     handleChoice([value])
        // } else {
        //     // var valueSet = [];
        //     // if (evt.target.checked) {
        //     //     valueSet.push(value);
        //     // }
        //     // else {
        //     //     valueSet.pop(value);
        //     // }

        //     const item = evt.target.name;
        //     const isChecked = evt.target.checked;
        //     setMultipleChoice(prevState => prevState.set( item, isChecked ) )

        //     
        // }
    }

    return (
        <React.Fragment>
            {options && options.length && options.map((item, idx) => (
                <div key={idx} className="d-flex align-items-start mb-2">
                    <span className="mr-2">{numberToLetter(idx + 1)}</span>
                    <Form.Check
                        type={type}
                        label={item.t}
                        name="option"
                        checked={choice && choice.includes(item.k)}
                        // value={choice}
                        onChange={() => handleUserChoice(item.k)}
                        disabled={disabled}
                    />
                </div>
            ))}
        </React.Fragment>
    )
}

export default OptionChoice
