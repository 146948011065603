import React, { useState, useRef, Fragment } from 'react'
import { useHistory } from "react-router-dom";
import Countdown from 'react-countdown';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";

import { endExam } from '../../../actions/examActions';
import { useDispatch } from 'react-redux';

const CountDownTimer = ({ duration, examId, pauseCount, resumeCount, text, onComplete }) => {
    const countDownRefExo = useRef();
    const [program, setProgram] = useState({
        step: 0, // not useful for the example
        date: Date.now() + (duration * 60000)
    })

    const [isPlaying, setIsPlaying] = useState(true);

    const dispatch = useDispatch()

    const handlePause = () => {
        countDownRefExo.current.pause();
    }

    const handlePlay = () => {
        countDownRefExo.current.start();
    }

    let history = useHistory();
    const handleComplete = () => {
        dispatch(endExam(examId, history, true));
    }

    const renderer = ({ formatted }) => {
        return <span>{Number((formatted.hours * 60)) + Number(formatted.minutes)}:{formatted.seconds}</span>;
    };

    function PlayScreen() {
        return (
            <Fragment>
                <div>
                    <h4>{text}</h4>
                    <div className="text-success font-weight-bold">
                        <Countdown
                            date={program.date}
                            ref={countDownRefExo}
                            renderer={renderer}
                            onComplete={onComplete || handleComplete}
                            
                        />
                        {pauseCount && countDownRefExo.current.pause()}
                        {resumeCount && countDownRefExo.current.start()}
                    </div>
                </div>
            </Fragment>
        )
    }



    return (
        <div>
            {PlayScreen()}
        </div>
    );
}

export default CountDownTimer
