const  numberToLetter = (value) => {
    return (value + 9).toString(36).toUpperCase();

}


const calculatePercentage = (total_question,total_correct_answered) => {
    return ((total_correct_answered * 100)/total_question).toFixed(2);
}

const handleCopy = (e) => {
    // alert("hello")
    // e.preventDefault()
    return true;
     
}

export {
    numberToLetter,
    calculatePercentage,
    handleCopy
}
