export const USER_LOGIN_REQEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCEESS = 'USER_LOGIN_SUCEESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'


export const USER_LOGOUT = 'USER_LOGOUT'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'


export const PASSWORD_CHANGE_REQUEST = 'PASSWORD_CHANGE_REQUEST'
export const PASWORD_CHANGE_SUCCESS = 'PASWORD_CHANGE_SUCCESS'
export const PASSWORD_CHANGE_FAIL = 'PASSWORD_CHANGE_FAIL'


export const DESTROY_SESSION = 'DESTROY_SESSION'