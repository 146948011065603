export const tableData = () => {
    return [
      {
        title: 'Category',
        render: rowData => {
          return <span>{rowData.category}</span>;
        },
      },
      {
        title: 'Sub Category',
        render: rowData => {
          return <span>{rowData.sub_category}</span>;
        },
      },
      {
        title: 'Number Of Question',
        render: rowData => {
          return <span>{rowData.number_of_question}</span>;
        },
      },
      {
        title: 'Correct Answer',
        render: rowData => {
          return <span>{rowData.correct_answer}</span>;
        },
      },
      {
        title: 'Correct Percentage',
        render: rowData => {
          return <span>{rowData.correct_percentage}</span>;
        },
      },
      // {
      //   title: 'Rating',
      //   render: rowData => {
      //     return <span>{rowData.rating}</span>;
      //   },
      // },
      
    ];
};