import React from 'react';
import "./ContentHeader.css";

const ContentHeader = ({title, sub_title}) => {
    return (
        <div className="content-wrap-pad">
            <div className="page_header_content">
                <h3 className="page_header_title align-items-start flex-column pb-1">
                    <span className="page_header_title_label text-dark">{title}</span>
                    <span className="text-muted font-size-sm mt-1 page_header_subtitle">{sub_title}</span>
                </h3>
            </div>
        </div>
    )
}

export default ContentHeader
