import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom'

import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';


import { Container } from 'react-bootstrap'
import "./App.css";

import NavBar from './components/layout/navbar/NavBar';
import SideBar from './components/layout/sidebar/SideBar';
import Message from './components/helpers/Message'

import ExamList from './screens/exam/my_exam/ExamList';
import CourseDetails from './screens/course/course_details/CourseDetails';
import CourseTopic from './screens/course/course_topic/CourseTopic';
import ExamSelect from './screens/exam/exam_select/ExamSelect';
import ExamForm from './screens/exam/exam_form/ExamForm';
import ExamDetails from './screens/exam/exam_details/ExamDetails';
import Forgot from "./screens/auth/forgot/Forgot";
import Faq from './screens/settings/faq/Faq';



import Home from './screens/home/Home';
import Login from './screens/auth/login/Login';
import ChangePassword from "./screens/auth/profile/change_password/ChangePassword";
import PersonalInfo from "./screens/auth/profile/personal_info/PersonalInfo";

import PrivateRoute from './components/auth/PrivateRoute';


import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';


import { setCurrentUser, logout, removeLocalStorage } from './actions/userActions';
import axios from 'axios';
import store from './store';
import AccountInfo from './screens/auth/profile/account_info/AccountInfo';


import { handelRightClick, keydownHandler } from './utils/rightClickDisable';

import { BASEURL } from "./utils/config";
import { _axios, _axiosNoInterceptor } from './actions/axios/axios_conf';
import { setRefreshTokenTimerRef } from './config/app';




//Alert Options
const alertOptions = {
	// you can also just use 'bottom center'
	position: positions.TOP_RIGHT,
	timeout: 3000,
	offset: '30px',
	// you can also just use 'scale'
	transition: transitions.SCALE
}







// setInterval(function() {
// 	
// 	if(userInfo.refreshToken){
// 		store.dispatch(loginRefresh(userInfo.refreshToken))
// 	}
// }, 60 * 1000); 	

// if (localStorage.jwtToken) {
// 	setAuthToken(localStorage.jwtToken);
// 	const decoded = jwt_decode(localStorage.jwtToken);
// 	store.dispatch(setCurrentUser(decoded));

// 	const currentTime = Date.now() / 1000;

// 	if (decoded.exp < currentTime) {
// 		
// 		store.dispatch(loginRefresh(userInfo.refreshToken));
// 		// window.location.href = '/login';
// 	}

// }



// Request interceptor for API calls
// axios.interceptors.request.use(
// 	async (config) => {
// 	  const token = localStorage.getItem("jwtToken");
// 	  config.headers = {
// 		Authorization: `Bearer ${token}`,
// 		Accept: "application/json",
// 		"Content-Type": "application/json",
// 	  };
// 	  return config;
// 	},
// 	(error) => {
// 	  Promise.reject(error);
// 	}
//   );

// Response interceptor for API calls
//   axios.interceptors.response.use(
// 	(response) => {
// 	  return response;
// 	},
// 	async function (error) {
// 	  const originalRequest = error.config;

// 	  


// 	  if (error.response.status === 401 && !originalRequest._retry) {
// 		originalRequest._retry = true;

// 		const refreshToken = window.localStorage.getItem("refreshToken");



// 		if (refreshToken) {
// 			
// 		  _axiosNoInterceptor
// 			.post(`${BASEURL}/login/refresh/`, {
// 			  refresh: userInfo.refreshToken,
// 			})
//   			.then((res) => {
// 			  if (res?.data?.access) {
// 				window.localStorage.setItem("jwtToken", res.data.access);
// 				axios.defaults.headers.common["Authorization"] =
// 				  "Bearer " + res.data.access;
// 				originalRequest.headers["Authorization"] =
// 				  "Bearer " + res.data.access;
// 				return axios(originalRequest);
// 			  }
// 			}).catch(err => {
// 				store.dispatch(removeLocalStorage)
// 			});
// 		}
// 	  }
// 	  if (error.response.status === 403) {
// 			store.dispatch(removeLocalStorage)
// 			window.location.href = '/login';
// 	  }
// 	  return Promise.reject(error);
// 	}
//   );





const App = () => {
	document.addEventListener('contextmenu', handelRightClick);
	document.addEventListener('keydown', keydownHandler);
	

	const [toggled, setToggled] = useState(false);
	const [collapsed, setCollapsed] = useState(false);
	const history = useHistory();

	const user = useSelector(state => state.user);
	const { userInfo } = user;
	const timerRef = useRef();
	const refreshInterval = (process.env.TOKEN_LIFESPAN || 172800000) - 86400000;

	useEffect(() => {
		if (userInfo?.refreshToken) initiateRefreshTokenTimer();

		return () => {
			clearRefreshTokenTimer();
		}

	}, [userInfo?.refreshToken])

	const clearRefreshTokenTimer = () => {
		timerRef.current && clearTimeout(timerRef.current);
	}

	const setRefreshTokenTimer = (delay) => {
		clearRefreshTokenTimer();
		timerRef.current = setTimeout(() => {
			initiateRefreshTokenTimer();
		}, delay)

		setRefreshTokenTimerRef(timerRef.current);
	}

	const initiateRefreshTokenTimer = () => {
		const decoded = jwt_decode(localStorage.jwtToken)
		const currentTime = Date.now();
		const expiresIn = (decoded.exp * 1000) - currentTime;
		const refreshToken = localStorage.getItem('refreshToken');

		if ((expiresIn <= refreshInterval)) {
			_axiosNoInterceptor
				.post(`${BASEURL}/login/refresh/`, {
					refresh: refreshToken,
				})
				.then((res) => {
					if (res?.data?.access) {
						localStorage.setItem("jwtToken", res.data.access);
						const newUserInfo = { ...jwt_decode(res.data.access), refreshToken };
						store.dispatch(setCurrentUser(newUserInfo));
					}
					setRefreshTokenTimer(refreshInterval);
				}).catch(err => {
					//take to login screen
					// history.push('/login');
					clearRefreshTokenTimer();
					localStorage.removeItem('userInfo');
					localStorage.removeItem('userCourses');
					localStorage.removeItem('jwtToken');
					localStorage.removeItem('refreshToken');
					window.location.href = "/login"
				});
		} else {
			setRefreshTokenTimer(expiresIn - refreshInterval);
		}
	}


	const handleCollapsedChange = (checked) => {
		setCollapsed(checked);
	};

	const handleToggleSidebar = (value) => {
		setToggled(value);
		setCollapsed(false);
	};



	let HideSideBar = userInfo ? <SideBar collapsed={collapsed} toggled={toggled} handleToggleSidebar={handleToggleSidebar} /> : null;
	let HideNavBar = userInfo ? <NavBar toggled={toggled} collapsed={collapsed} handleToggleSidebar={handleToggleSidebar}
		handleCollapsedChange={handleCollapsedChange} /> : null


	return (
		<React.Fragment>
			<AlertProvider template={AlertTemplate} {...alertOptions}>
				<Router>
					<main>
						<Message />
						<div className="App">
							{HideSideBar}
							<Container fluid className="p-0">
								{HideNavBar}
								<div className={`${collapsed ? 'content-collpased' : 'content'} mt-3 mr-2 pb-5`}>
									<Route path='/login' component={Login} exact />
									<Route path='/forgot' component={Forgot} exact />


									<Switch>
										<PrivateRoute exact path="/" component={ExamList} />
									</Switch>
									<Switch>
										<PrivateRoute exact path="/exam-list" component={ExamList} />
									</Switch>

									<Switch>
										<PrivateRoute exact path="/exam/details/:id" component={ExamDetails} />
									</Switch>

									<Switch>
										<PrivateRoute exact path="/course/:id" component={CourseDetails} />
									</Switch>

									<Switch>
										<PrivateRoute exact path="/course/topic/:id/:type" component={CourseTopic} />
									</Switch>

									<Switch>
										<PrivateRoute exact path="/exam/select/:id" component={ExamSelect} />
									</Switch>

									<Switch>
										<PrivateRoute exact path="/exam/exam-form/:examId?" component={ExamForm} />
									</Switch>
									<Switch>
										<PrivateRoute exact path="/change-password" component={ChangePassword} />
									</Switch>
									<Switch>
										<PrivateRoute exact path="/personal-info" component={PersonalInfo} />
									</Switch>
									<Switch>
										<PrivateRoute exact path="/account-info" component={AccountInfo} />
									</Switch>

									<Switch>
										<PrivateRoute exact path="/faq" component={Faq} />
									</Switch>

								</div>

							</Container>
						</div>
					</main>
				</Router>
			</AlertProvider>

		</React.Fragment >
	);
}

export default App;