import React from 'react';
import VideoPlayer from "./VideoPlayer";
import "./VideoSection.css";
import { Row, Col } from 'react-bootstrap';
import { Collapse } from 'antd';
const { Panel } = Collapse;

const VideoSection = ({item}) => {

    return (
        <>
            <div className="custom_course_topic_card_body">
                <Row className="">
                    {item.contents.length > 0 ? 
                        item.contents.map((cnt, idx) => (
                            <Col lg={4} md={8} sm={8} className="mb-3" key={idx}>
                                <div className="" key={idx}>
                                    <div className="fs-6 mb-2  pointer cp">
                                        <>
                                            <VideoPlayer url={cnt.file} />
                                            {/* {console.log("Video url", cnt.file)} */}
                                            <span>{cnt.title}</span>
                                        </>
                                    </div>
                                </div>
                            </Col>
                            ))
                            :
                            (
                                <p className='not_found'>No Video Found</p>
                            )
                }
                </Row>
            </div>         
        </>
    )
}

export default VideoSection